import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useAdminUserStore from "@/stores/useAdminUserStore"

import { Button } from "@repo/ui/components/ui/button"

import BasicInformation from "./sections/BasicInformation"
import BusinessSetting from "./sections/BusinessSetting"
import BusinessBank from "./sections/BusinessBank"

const FormSchema = z
  .object({
    image: z.any().refine((file) => file !== null, "Image is required"),
    first_name: z.string().min(1, { message: "First name is required" }),
    last_name: z.string().min(1, { message: "Last name is required" }),
    email: z.string().email({
      message: "Please enter a valid email address"
    }),
    mobile_number: z.string().min(1, {
      message: "Please enter a valid mobile number"
    }),
    role: z.string().min(1, { message: "User type is required" }),

    entity_type: z.string().min(1, { message: "Entity type is required" }),
    registered_business_name: z
      .string()
      .min(1, { message: "Registered business name is required" }),
    australian_business_number: z
      .string()
      .min(1, { message: "Australian business number is required" }),
    australian_company_number: z.string(),
    entity_email: z.string().min(1, { message: "Entity email address is required" }).email({
      message: "Please enter a valid email address"
    }),
    entity_contact_number: z.string().min(1, { message: "Entity contact number is required" }),
    website: z.string().min(1, { message: "Website is required" }).url({
      message: "Please enter a valid website url"
    }),
    entity_industry: z.string().min(1, { message: "Entity industry is required" }),
    street_address: z.string().min(1, { message: "Street address is required" }),
    suburb: z.string().min(1, { message: "Suburb is required" }),
    state: z.string().min(1, { message: "State is required" }),
    postcode: z.string().min(1, { message: "Postcode is required" }),
    membership_association: z.string().min(1, { message: "Association is required" }),
    membership_certificate: z
      .any()
      .refine((file) => file !== null, "Membership Certificate is required"),
    membership_expiry: z.coerce.date(),
    acl_certificate: z
      .any()
      .refine((file) => file !== null, "Australian Credit Licence is required"),
    acl_expiry: z.coerce.date(),
    pii_certificate: z.any().refine((file) => file !== null, "Certificate of Currency is required"),
    pii_expiry: z.coerce.date(),

    account_name: z.string().min(1, { message: "Name of account is required" }),
    bank_branch: z.string().min(1, { message: "Bank branch is required" }),
    other: z.string(),
    bsb: z.string().min(1, { message: "BSB is required" }),
    account_number: z.string().min(1, { message: "Account number is required" }),
    statement_proof: z.any().refine((file) => file !== null, "Proof of bank statement is required")
  })
  .refine(
    (data) => {
      if (
        (data.entity_type === "Company" && !data.australian_company_number) ||
        (data.entity_type === "Trust" && !data.australian_company_number)
      ) {
        return false
      }
      return true
    },
    {
      message: "Australian company number is required",
      path: ["australian_company_number"]
    }
  )
  .refine(
    (data) => {
      if (data.bank_branch === "Other" && !data.other) {
        return false
      }
      return true
    },
    {
      message: "Other is required",
      path: ["other"]
    }
  )

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: any
}

export default function BrokerForm({ data }: Readonly<Props>) {
  const store = useAdminUserStore()
  const navigate = useNavigate()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.2 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 1 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      role: "",

      entity_type: "",
      registered_business_name: "",
      australian_business_number: "",
      australian_company_number: "",
      entity_email: "",
      entity_contact_number: "",
      website: "",
      entity_industry: "",
      street_address: "",
      suburb: "",
      state: "",
      postcode: "",
      membership_association: "",
      membership_certificate: null,
      membership_expiry: undefined,
      acl_certificate: null,
      acl_expiry: undefined,
      pii_certificate: null,
      pii_expiry: undefined,

      account_name: "",
      bank_branch: "",
      other: "",
      bsb: "",
      account_number: "",
      statement_proof: null
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/users/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(data.uuid, values, () => {
      navigate("/admin/users/list")
    })
  }

  useEffect(() => {
    form.reset({
      image: data?.image,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      mobile_number: data?.mobile_number,
      role: data?.role,

      entity_type: data.bs?.entity_type ?? "",
      registered_business_name: data.bs?.registered_business_name ?? "",
      australian_business_number: data.bs?.australian_business_number ?? "",
      australian_company_number: data.bs?.australian_company_number ?? "",
      entity_email: data.bs?.entity_email ?? "",
      entity_contact_number: data.bs?.entity_contact_number ?? "",
      website: data.bs?.website ?? "",
      entity_industry: data.bs?.entity_industry ?? "",
      street_address: data.bs?.street_address ?? "",
      suburb: data.bs?.suburb ?? "",
      state: data.bs?.state ?? "",
      postcode: data.bs?.postcode ?? "",
      membership_association: data.bs?.membership_association ?? "",
      membership_certificate: data.bs?.membership_certificate ?? null,
      membership_expiry: data.bs?.membership_expiry ?? undefined,
      acl_certificate: data.bs?.acl_certificate ?? null,
      acl_expiry: data.bs?.acl_expiry ?? undefined,
      pii_certificate: data.bs?.pii_certificate ?? null,
      pii_expiry: data.bs?.pii_expiry ?? undefined,

      account_name: data.bb?.account_name ?? "",
      bank_branch: data.bb?.bank_branch ?? "",
      other: data.bb?.other ?? "",
      bsb: data.bb?.bsb ?? "",
      account_number: data.bb?.account_number ?? "",
      statement_proof: data.bb?.statement_proof ?? null
    })
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <div ref={ref1} id="basic-information">
              <BasicInformation />
            </div>

            <div ref={ref2} id="business-setting">
              <BusinessSetting businessDocuments={data?.businessDocuments ?? []} />
            </div>

            <div ref={ref3} id="business-bank">
              <BusinessBank />
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "basic-information",
                label: "Basic information",
                inView: inView1
              },
              {
                id: "business-setting",
                label: "Business Setting Information",
                inView: inView2
              },
              {
                id: "business-bank",
                label: "Business Bank Information",
                inView: inView3
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton loading={store.loading} type="submit" className="px-6 py-4 md:!w-auto">
            Update account
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}
