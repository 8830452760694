import React from "react"

import { ColumnDef } from "@tanstack/react-table"
import { format } from "date-fns"
import { useNavigate } from "react-router-dom"

import { IconArchive, IconMore, IconView } from "@/components/_icons"
import useLeadStore from "@/stores/useLeadStore"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Button } from "@repo/ui/components/ui/button"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useLeadStore()

  const handleViewClick = (uuid: string) => () => {
    navigate(`/leads/view/${uuid}`)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleViewClick(row.original.uuid)}
          >
            <IconView className="text-base" /> View
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleArchiveClick(row.original.uuid)}
          >
            <IconArchive className="text-base" />
            {!row.original.is_archived ? "Archive" : "Restore"}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "created_at",
    header: () => <>Submission Time</>,
    cell: ({ row }) => (
      <div className="text-sm">{format(row.original.created_at, "dd/MM/yyyy")}</div>
    )
  },
  {
    accessorKey: "ful_name",
    header: () => <>Full Name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.full_name}</div>
  },
  {
    accessorKey: "entity_type",
    header: () => <>Mobile Number</>,
    cell: ({ row }) => <div className="text-sm">{row.original.mobile_number}</div>
  },
  {
    accessorKey: "email",
    header: () => <>Email Address</>,
    cell: ({ row }) => <div className="text-sm">{row.original.email}</div>
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
