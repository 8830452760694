import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { format } from "date-fns"
import { FormProvider, useFieldArray, useForm } from "react-hook-form"
import { LuCheck, LuX } from "react-icons/lu"
import { InView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import { Button } from "@repo/ui/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@repo/ui/components/ui/tooltip"
import { CHECK_ITEM_TYPES, YN } from "@repo/util/constant"

import SupportingDocumentationItems from "./SupportingDocumentationItems"
import { LOADING_TIMEOUT } from "../../util"
import { useAppContext } from "../../AppContext"
import InviteBorrowerDialog from "../components/InviteBorrowerDialog"

const FormSchema = z.object({
  checkitems: z
    .array(
      z.object({
        section: z.string(),
        items: z
          .array(
            z
              .object({
                type: z.string(),
                text: z.string(),
                choice: z.enum([YN.Yes, YN.No]).default(YN.Yes),
                result: z.union([z.string(), z.instanceof(File)]).optional()
              })
              .superRefine((data, ctx) => {
                if (data.choice === YN.Yes && !data.result) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ["result"],
                    message: "This field is required"
                  })
                }
              })
          )
          .default([])
      })
    )
    .default([])
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function SupportingDocumentation() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAppContext()
  const navigate = useNavigate()
  const store = useApplicationDraftStore()

  const [inviews, setInviews] = useState<boolean[]>([])
  const [openAction, setOpenAction] = useState(false)
  const [openInviteBorrower, setOpenInviteBorrower] = useState(false)
  const [openIDVerification, setOpenIDVerification] = useState(false)
  const [openConsentToAct, setOpenConsentToAct] = useState(false)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      checkitems: []
    }
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: "checkitems"
  })

  const initialize = () => {
    const fslt = data?.lender?.fs
      ?.filter((fs: any) => fs.funding_solution === data.funding_solution)?.[0]
      ?.lt?.filter((lt: any) => lt.loan_type === data.loan_type)?.[0]

    if (fslt?.checkitems?.length > 0) {
      const updatedInviews = Array(fslt?.checkitems?.length).fill(false)
      updatedInviews[0] = true
      setInviews(updatedInviews)

      const updatedCheckitems: any[] = []
      fslt?.checkitems.forEach((i: any) => {
        const subitems = i.items
        const items: any[] = []

        subitems.forEach((si: any) => {
          items.push({
            ...si,
            choice: si.type === CHECK_ITEM_TYPES.YesNo ? YN.Yes : YN.No,
            result: ""
          })
        })

        updatedCheckitems.push({
          section: i.section,
          items
        })
      })
      form.setValue("checkitems", data?.checkitems ? data?.checkitems : updatedCheckitems)
    }
  }

  const handleInviteBorrowerClick = () => {
    setOpenAction(false)
    setOpenInviteBorrower(true)
  }

  const handleIDVerificationRequestClick = () => {
    setOpenAction(false)
    setOpenIDVerification(true)
  }

  const handleConsentToActRequestClick = () => {
    setOpenAction(false)
    setOpenConsentToAct(true)
  }

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    initialize()
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-8">
            <h3 className="text-main mb-2 text-lg font-semibold">Supporting Documents</h3>

            <p className="text-default mb-6 text-sm font-normal">
              Please attach supporting documents.
            </p>

            <div className="flex w-full flex-col">
              {fields.map((item, index) => (
                <InView
                  key={item.id}
                  id={`iv-${item.section.replaceAll(" ", "_")}`}
                  threshold={0.1}
                  className="mb-8 flex flex-col"
                  onChange={(inView, entry) => {
                    setInviews((prev) => {
                      const updatedValue = JSON.parse(JSON.stringify(prev))
                      updatedValue[index] = inView
                      return updatedValue
                    })
                  }}
                >
                  <h3 className="text-main mb-4 text-lg font-semibold">{item.section}</h3>

                  <SupportingDocumentationItems checkItemIndex={index} />
                </InView>
              ))}
            </div>
          </div>

          <StickyShortcut
            // items={form.watch("checkitems").map((ci: any, index: number) => ({
            //   id: `iv-${ci.section.replaceAll(" ", "_")}`,
            //   label: ci.section,
            //   inView: inviews[index]
            // }))}
            unitHeight={40}
            hasWidget
            widgetBefore={
              <div className="flex flex-col">
                <Popover open={openAction} onOpenChange={setOpenAction}>
                  <PopoverTrigger
                    type="button"
                    className="border-primary bg-primary hover:bg-primary mb-6 h-auto w-full rounded-xl border px-6 py-3 text-sm font-semibold text-white"
                  >
                    Actions
                  </PopoverTrigger>

                  <PopoverContent
                    className="bg-background popover-content-width-same-as-its-trigger flex flex-col gap-2 rounded-xl"
                    sideOffset={10}
                  >
                    <Button
                      type="button"
                      className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                      onClick={handleInviteBorrowerClick}
                    >
                      Invite Borrower
                    </Button>

                    <Button
                      type="button"
                      className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                      onClick={handleIDVerificationRequestClick}
                    >
                      ID Verification Request
                    </Button>

                    <Button
                      type="button"
                      className="text-default h-auto border border-[#868194] bg-transparent px-6 py-3 font-semibold hover:bg-transparent"
                      onClick={handleConsentToActRequestClick}
                    >
                      Consent to Act Request
                    </Button>
                  </PopoverContent>
                </Popover>

                <div className="bg-background mb-6 flex flex-col rounded-xl border p-6">
                  <div className="text-main mb-4 text-sm font-semibold">Created On</div>

                  <div className="text-main mb-4 text-sm font-normal">
                    Date:{" "}
                    <span className="text-default text-sm font-normal">
                      {data?.created_at && format(data?.created_at, "dd/MM/yyyy")}
                    </span>
                  </div>

                  <div className="text-main mb-4 text-sm font-normal">
                    Time:{" "}
                    <span className="text-default text-sm font-normal">
                      {data?.created_at && format(data?.created_at, "h:mm aaa")}
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <div className="text-main text-sm font-normal">Collaborators:</div>

                    <TooltipProvider
                      disableHoverableContent
                      delayDuration={0}
                      skipDelayDuration={0}
                    >
                      <div className="flex items-center">
                        <Tooltip>
                          <TooltipTrigger>
                            <Avatar className="hover:border-primary h-7 w-7 border-2 border-white hover:z-50">
                              <AvatarImage
                                src={data?.creator?.image}
                                alt={`${data?.creator?.first_name} ${data?.creator?.last_name}`}
                              />
                              <AvatarFallback
                                className="uppercase"
                                onClick={(e) => e.preventDefault()}
                              >
                                {data?.creator?.first_name[0]}
                                {data?.creator?.last_name[0]}
                              </AvatarFallback>
                            </Avatar>
                          </TooltipTrigger>

                          <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                            <p className="text-default text-sm font-normal">Full Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {data?.creator?.first_name} {data?.creator?.last_name}
                            </p>
                            <p className="text-default text-sm font-normal">Entity Name:</p>
                            <p className="text-main col-span-2 text-sm font-semibold">
                              {data?.creator?.bs?.registered_business_name}
                            </p>
                          </TooltipContent>
                        </Tooltip>

                        {data?.assignees?.map((a: any) => (
                          <Tooltip key={a?.user?.id}>
                            <TooltipTrigger>
                              <Avatar className="hover:border-primary ml-[-12px] h-7 w-7 border-2 border-white hover:z-50">
                                <AvatarImage
                                  src={a?.user?.image}
                                  alt={`${a?.user?.first_name} ${a?.user?.last_name}`}
                                />
                                <AvatarFallback
                                  className="uppercase"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  {a?.user?.first_name[0]}
                                  {a?.user?.last_name[0]}
                                </AvatarFallback>
                              </Avatar>
                            </TooltipTrigger>

                            <TooltipContent className="grid grid-cols-3 gap-2 p-4">
                              <p className="text-default text-sm font-normal">Full Name:</p>
                              <p className="text-main col-span-2 text-sm font-semibold">
                                {a?.user?.first_name} {a?.user?.last_name}
                              </p>
                              <p className="text-default text-sm font-normal">Entity Name:</p>
                              <p className="text-main col-span-2 text-sm font-semibold">
                                {a?.user?.bu?.manager?.bs?.registered_business_name}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        ))}
                      </div>
                    </TooltipProvider>
                  </div>
                </div>

                <div className="bg-background mb-6 flex flex-col rounded-xl border p-6">
                  <div className="text-main mb-4 text-sm font-semibold">Authorisations</div>

                  <div className="mb-4 flex flex-col gap-4">
                    <div className="text-main text-sm font-normal">Consent to Act:</div>
                    {[
                      { name: "John Doe", is_consented: true },
                      { name: "John Smith", is_consented: false }
                    ].map((c) => (
                      <div key={c.name} className="flex items-center gap-2">
                        <span className="text-default text-sm font-normal">{c.name}</span>
                        <div
                          className={clsx(
                            "flex h-4 w-4 items-center justify-center rounded-full text-white",
                            c.is_consented ? "bg-primary" : "bg-[#F59E0B]"
                          )}
                        >
                          {c.is_consented ? (
                            <LuCheck className="text-xs" />
                          ) : (
                            <LuX className="text-xs" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-col gap-4">
                    <div className="text-main text-sm font-normal">ID Verification:</div>
                    {[
                      { name: "John Doe", is_verified: false },
                      { name: "John Smith", is_verified: true }
                    ].map((c) => (
                      <div key={c.name} className="flex items-center gap-2">
                        <span className="text-default text-sm font-normal">{c.name}</span>
                        <div
                          className={clsx(
                            "flex h-4 w-4 items-center justify-center rounded-full text-white",
                            c.is_verified ? "bg-primary" : "bg-[#F59E0B]"
                          )}
                        >
                          {c.is_verified ? (
                            <LuCheck className="text-xs" />
                          ) : (
                            <LuX className="text-xs" />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            }
          />
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>

      <InviteBorrowerDialog open={openInviteBorrower} setOpen={setOpenInviteBorrower} />
    </FormProvider>
  )
}
