import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { PatternFormat } from "react-number-format"
import * as z from "zod"

import { IconCreateApplication } from "@/components/_icons"
import useLeadStore from "@/stores/useLeadStore"
import { LoadingButton } from "@/components/_uiext"

import { Button } from "@repo/ui/components/ui/button"
import { Dialog, DialogContent, DialogTrigger } from "@repo/ui/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Textarea } from "@repo/ui/components/ui/textarea"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { SITE_TITLE } from "@repo/util/constant"

const FormSchema = z.object({
  full_name: z.string().min(1, { message: "This field is required" }),
  mobile_number: z.string().min(1, { message: "This field is required" }),
  email: z.string().email({ message: "This field is required" }),
  requirement: z.string().min(1, { message: "This field is required" }),
  is_agreed: z.boolean({
    invalid_type_error: "This field is required",
    required_error: "This field is required"
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  type: "button" | "link"
}

export default function NewLeadDialog(props: Readonly<Props>) {
  const { type } = props
  const store = useLeadStore()
  const [open, setOpen] = useState(false)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      full_name: "",
      mobile_number: "",
      email: "",
      requirement: ""
    }
  })

  const onSubmit = (values: FormSchemaType) => {
    store.createAction(values, () => {
      form.reset()
      setOpen(false)
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {type === "button" && (
        <DialogTrigger asChild>
          <Button className="h-auto w-full rounded-xl py-4 text-sm font-semibold text-white">
            <IconCreateApplication className="mr-1 text-2xl" /> Add new lead
          </Button>
        </DialogTrigger>
      )}

      {type === "link" && (
        <DialogTrigger asChild>
          <div className="flex cursor-pointer items-center gap-1 py-1 text-sm">
            <IconCreateApplication className="text-xl" />
            Add New Lead
          </div>
        </DialogTrigger>
      )}

      <DialogContent className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto">
        <Form {...form}>
          <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
            <div className="text-main mb-4 text-center text-lg font-semibold">Add new lead</div>

            <p className="text-default mb-6 text-center text-base font-normal">
              Please enter the details of your lead.
            </p>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="full_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">Full Name</FormLabel>
                    <Input autoFocus autoComplete="off" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="mobile_number"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">
                      Mobile Number
                    </FormLabel>
                    <PatternFormat
                      customInput={Input}
                      format="04## ### ###"
                      mask="_"
                      allowEmptyFormatting={true}
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value.slice(2) ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.formattedValue)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">
                      Email Address
                    </FormLabel>
                    <Input autoComplete="off" type="email" {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="requirement"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main mb-2 text-sm font-normal">
                      Tell us about your leads requirement
                    </FormLabel>
                    <Textarea {...field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="is_agreed"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        I agree to a phone call introduction with the borrower and a {SITE_TITLE}{" "}
                        representative.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="text-center">
              <LoadingButton loading={store.loading} type="submit" className="px-4 py-4 md:!w-auto">
                Submit new lead
              </LoadingButton>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
