import React from "react"

import clsx from "clsx"
import { useFormContext } from "react-hook-form"
import { PatternFormat } from "react-number-format"

import { AvatarUpload } from "@/components/_uiext"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { USER_ROLES } from "@repo/util/constant"

export default function BasicInformation() {
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-main mb-4 py-4 text-xl font-semibold">Basic information</h2>

      <div className="mb-4">
        <FormField
          control={form.control}
          name="image"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="text-main text-sm font-normal">Image</FormLabel>
              <AvatarUpload
                placeholder={`${form.watch("first_name")?.[0] ?? ""}${form.watch("last_name")?.[0] ?? ""}`}
                {...field}
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="first_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">First name</FormLabel>
                <Input autoFocus autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="last_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Last name</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                <Input autoComplete="off" type="email" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="mobile_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
                <PatternFormat
                  customInput={Input}
                  format="04## ### ###"
                  mask="_"
                  allowEmptyFormatting={true}
                  getInputRef={field.ref}
                  name={field.name}
                  value={field.value.slice(2) ?? ""}
                  onBlur={field.onBlur}
                  onValueChange={(values, sourceInfo) => {
                    field.onChange(values.formattedValue)
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="role"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">User type</FormLabel>
                <Select onValueChange={field.onChange} value={field.value} disabled>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm capitalize",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {[
                      USER_ROLES.Employee,
                      USER_ROLES.Broker,
                      USER_ROLES.Introducer,
                      USER_ROLES.Enterprise,
                      USER_ROLES.Administrator
                    ].map((r) => (
                      <SelectItem key={r} value={r} className="capitalize">
                        {r}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>
    </div>
  )
}
