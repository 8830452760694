import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { useFieldArray, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { NumericFormat } from "react-number-format"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { Form, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  ASSETS_TYPES,
  ENTITY_INDUSTRIES,
  ENTITY_TYPES,
  LIABILITIES_TYPES
} from "@repo/util/constant"
import { Input } from "@repo/ui/components/ui/input"

import BorrowerSummary from "../components/summary/BorrowerSummary"
import { LOADING_TIMEOUT } from "../../util"
import { useAdminAppContext } from "../../AdminAppContext"

const FormSchema = z.object({
  guarantors: z.array(
    z.object({
      entity_type: z.string().min(1, { message: "Entity type is required" }),
      registered_business_name: z
        .string()
        .min(1, { message: "Registered business name is required" }),
      australian_business_number: z
        .string()
        .min(1, { message: "Australian business number is required" }),
      australian_company_number: z.string(),
      website: z.string().min(1, { message: "Website is required" }).url({
        message: "Please enter a valid website url"
      }),
      entity_industry: z.string().min(1, { message: "Entity industry is required" }),

      full_name: z.string().min(1, { message: "Full name is required" }),
      email: z.string().min(1, { message: "Email address is required" }),
      mobile_number: z.string().min(1, { message: "Mobile number is required" })
    })
  ),

  assets: z.array(
    z.object({
      assets_type: z.string().min(1, { message: "This field is required" }),
      assets_value: z.coerce.number({ invalid_type_error: "This field is required" }),
      assets_owner: z.string().min(1, { message: "This field is required" }),
      assets_ownership_percentage: z.coerce.number({ invalid_type_error: "This field is required" })
    })
  ),

  liabilities: z.array(
    z.object({
      liabilities_type: z.string().min(1, { message: "This field is required" }),
      liabilities_balance: z.coerce.number({ invalid_type_error: "This field is required" }),
      liabilities_lender: z.string().min(1, { message: "This field is required" }),
      liabilities_debt_percentage: z.coerce.number({ invalid_type_error: "This field is required" })
    })
  )
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function BorrowerDetails() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAdminAppContext()
  const navigate = useNavigate()
  const store = useAdminApplicationDraftStore()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.1 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 0.1 })
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 0.1 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      guarantors: [
        {
          entity_type: "",
          registered_business_name: "",
          australian_business_number: "",
          australian_company_number: "",
          website: "",
          entity_industry: "",
          full_name: "",
          email: "",
          mobile_number: ""
        }
      ],
      assets: [
        {
          assets_type: "",
          assets_value: 0,
          assets_owner: "",
          assets_ownership_percentage: 0
        }
      ],
      liabilities: [
        {
          liabilities_type: "",
          liabilities_balance: 0,
          liabilities_lender: "",
          liabilities_debt_percentage: 0
        }
      ]
    }
  })

  const {
    fields: fieldsGuarantors,
    append: appendGuarantors,
    remove: removeGuarantors
  } = useFieldArray({
    control: form.control,
    name: "guarantors"
  })

  const {
    fields: fieldsAssets,
    append: appendAssets,
    remove: removeAssets
  } = useFieldArray({
    control: form.control,
    name: "assets"
  })

  const {
    fields: fieldsLiabilities,
    append: appendLiabilities,
    remove: removeLiabilities
  } = useFieldArray({
    control: form.control,
    name: "liabilities"
  })

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/admin/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      guarantors: data?.guarantors?.length
        ? data?.guarantors
        : data.borrower?.directors?.map((d: any) => ({
            entity_type: data.borrower.entity_type,
            registered_business_name: data.borrower.registered_business_name,
            australian_business_number: data.borrower.australian_business_number,
            australian_company_number: data.borrower.australian_company_number,
            website: data.borrower.website,
            entity_industry: data.borrower.entity_industry,
            full_name: d.full_name,
            email: d.email,
            mobile_number: d.mobile_number
          })),
      assets: data?.assets?.length
        ? data?.assets
        : [
            {
              assets_type: "",
              assets_value: 0,
              assets_owner: "",
              assets_ownership_percentage: 0
            }
          ],
      liabilities: data?.liabilities?.length
        ? data?.liabilities
        : [
            {
              liabilities_type: "",
              liabilities_balance: 0,
              liabilities_lender: "",
              liabilities_debt_percentage: 0
            }
          ]
    })
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-8">
            <div ref={ref1} id="assets-liabilities">
              <h3 className="text-main mb-2 text-lg font-semibold">Assets & Liabilities</h3>

              <p className="text-default mb-4 text-sm font-normal">
                Please enter assets & liabilities.
              </p>

              <div className="mb-8">
                <BorrowerSummary data={data} />
              </div>
            </div>

            <div ref={ref2} id="guarantor-details">
              <Accordion type="multiple" className="w-full">
                <AccordionItem className="mb-4 rounded-2xl border p-6" value="guarantors">
                  <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
                    <div className="flex items-center justify-between gap-4">
                      <h3 className="text-main  text-lg font-semibold">Guarantor Details</h3>
                      <span
                        className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
                        onClick={() =>
                          appendGuarantors({
                            entity_type: "",
                            registered_business_name: "",
                            australian_business_number: "",
                            australian_company_number: "",
                            website: "",
                            entity_industry: "",
                            full_name: "",
                            email: "",
                            mobile_number: ""
                          })
                        }
                      >
                        Add guarantor
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col px-1 pt-6">
                    {fieldsGuarantors.map((item, index) => (
                      <div key={item.id} className="mb-4 flex flex-col rounded-xl border p-4">
                        <div className="mb-4 flex w-full justify-end">
                          <Button
                            type="button"
                            size="sm"
                            variant="destructive"
                            onClick={() => removeGuarantors(index)}
                          >
                            Remove
                          </Button>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.entity_type`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Entity type{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Select onValueChange={field.onChange} value={field.value}>
                                    <SelectTrigger
                                      className={clsx(
                                        "h-12 w-full text-sm",
                                        field.value ? "text-main" : "text-default"
                                      )}
                                    >
                                      <SelectValue placeholder="Please select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                                        <SelectItem key={k} value={v.key}>
                                          {v.key}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.registered_business_name`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Registered business name{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.australian_business_number`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Australian business number (ABN){" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <div
                              className={clsx(
                                "",
                                form.watch(`guarantors.${index}.entity_type`) ===
                                  ENTITY_TYPES.CompanyPrivate.key ||
                                  form.watch(`guarantors.${index}.entity_type`) ===
                                    ENTITY_TYPES.CompanyPublic.key ||
                                  form.watch(`guarantors.${index}.entity_type`) ===
                                    ENTITY_TYPES.Trust.key
                                  ? "block"
                                  : "hidden"
                              )}
                            >
                              <FormField
                                control={form.control}
                                name={`guarantors.${index}.australian_company_number`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel className="text-main text-sm font-normal">
                                      Australian company number (ACN){" "}
                                      <span className="text-default italic">required</span>
                                    </FormLabel>
                                    <Input autoComplete="off" {...field} />
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.website`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Website <span className="text-default italic">optional</span>
                                  </FormLabel>
                                  <Input type="url" autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.entity_industry`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Entity industry{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Select onValueChange={field.onChange} value={field.value}>
                                    <SelectTrigger
                                      className={clsx(
                                        "h-12 w-full text-sm",
                                        field.value ? "text-main" : "text-default"
                                      )}
                                    >
                                      <SelectValue placeholder="Please select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {ENTITY_INDUSTRIES.map((ei) => (
                                        <SelectItem key={ei} value={ei}>
                                          {ei}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.full_name`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Full name
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.email`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Email address
                                  </FormLabel>
                                  <Input type="email" autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`guarantors.${index}.mobile_number`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Mobile number
                                  </FormLabel>
                                  <Input autoComplete="off" type="tel" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="hidden flex-1 md:block" />
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>

            <div ref={ref3} id="assets">
              <Accordion type="multiple" className="w-full">
                <AccordionItem className="mb-4 rounded-2xl border p-6" value="assets">
                  <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
                    <div className="flex items-center justify-between gap-4">
                      <h3 className="text-main  text-lg font-semibold">Assets</h3>
                      <span
                        className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
                        onClick={() =>
                          appendAssets({
                            assets_type: "",
                            assets_value: 0,
                            assets_owner: "",
                            assets_ownership_percentage: 0
                          })
                        }
                      >
                        Add assets
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col px-1 pt-6">
                    {fieldsAssets.map((item, index) => (
                      <div key={item.id} className="mb-4 flex flex-col rounded-xl border p-4">
                        <div className="mb-4 flex w-full justify-end">
                          <Button
                            type="button"
                            size="sm"
                            variant="destructive"
                            onClick={() => removeAssets(index)}
                          >
                            Remove
                          </Button>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`assets.${index}.assets_type`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    What assets does the borrower own?{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Select onValueChange={field.onChange} value={field.value}>
                                    <SelectTrigger
                                      className={clsx(
                                        "h-12 w-full text-sm",
                                        field.value ? "text-main" : "text-default"
                                      )}
                                    >
                                      <SelectValue placeholder="Please select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.entries(ASSETS_TYPES).map(([k, v]) => (
                                        <SelectItem key={k} value={v}>
                                          {v}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`assets.${index}.assets_value`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Value <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    prefix="$"
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`assets.${index}.assets_owner`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Who is the owner of the asset?{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`assets.${index}.assets_ownership_percentage`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Ownership percentage{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    prefix="%"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>

            <div ref={ref4} id="liabilities">
              <Accordion type="multiple" className="w-full">
                <AccordionItem className="mb-4 rounded-2xl border p-6" value="liabilities">
                  <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
                    <div className="flex items-center justify-between gap-4">
                      <h3 className="text-main  text-lg font-semibold">Liabilities</h3>
                      <span
                        className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 text-sm font-semibold hover:bg-transparent"
                        onClick={() =>
                          appendLiabilities({
                            liabilities_type: "",
                            liabilities_balance: 0,
                            liabilities_lender: "",
                            liabilities_debt_percentage: 0
                          })
                        }
                      >
                        Add liabilities
                      </span>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent className="flex flex-col px-1 pt-6">
                    {fieldsLiabilities.map((item, index) => (
                      <div key={item.id} className="mb-4 flex flex-col rounded-xl border p-4">
                        <div className="mb-4 flex w-full justify-end">
                          <Button
                            type="button"
                            size="sm"
                            variant="destructive"
                            onClick={() => removeLiabilities(index)}
                          >
                            Remove
                          </Button>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`liabilities.${index}.liabilities_type`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    What liabilities does the borrower have?{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Select onValueChange={field.onChange} value={field.value}>
                                    <SelectTrigger
                                      className={clsx(
                                        "h-12 w-full text-sm",
                                        field.value ? "text-main" : "text-default"
                                      )}
                                    >
                                      <SelectValue placeholder="Please select" />
                                    </SelectTrigger>
                                    <SelectContent>
                                      {Object.entries(LIABILITIES_TYPES).map(([k, v]) => (
                                        <SelectItem key={k} value={v}>
                                          {v}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`liabilities.${index}.liabilities_balance`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Balance / limit owing{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    prefix="$"
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>

                        <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`liabilities.${index}.liabilities_lender`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    Who is the lender?{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <Input autoComplete="off" {...field} />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>

                          <div className="flex-1">
                            <FormField
                              control={form.control}
                              name={`liabilities.${index}.liabilities_debt_percentage`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel className="text-main text-sm font-normal">
                                    What is your borrowers percentage of debt?{" "}
                                    <span className="text-default italic">required</span>
                                  </FormLabel>
                                  <NumericFormat
                                    customInput={Input}
                                    thousandSeparator
                                    suffix="%"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    getInputRef={field.ref}
                                    name={field.name}
                                    value={field.value ?? ""}
                                    onBlur={field.onBlur}
                                    onValueChange={(values, sourceInfo) => {
                                      field.onChange(values.value)
                                    }}
                                  />
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "assets-liabilities",
                label: "Assets & Liabilities",
                inView: inView1
              },
              {
                id: "guarantor-details",
                label: "Guarantor Details",
                inView: inView2
              },
              {
                id: "assets",
                label: "Assets",
                inView: inView3
              },
              {
                id: "liabilities",
                label: "Liabilities",
                inView: inView4
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.locked}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
