import qs from "qs"

import api from "./api"

// Get borrowers
export const apiGetBorrowersAtAdminApplicationDraft = (applicationUuid: string, params: any) =>
  api().get(`/admin/application/drafts/${applicationUuid}/get-borrowers?${qs.stringify(params)}`)

// Find lenders
export const apiFindLendersAtAdminApplicationDraft = (applicationUuid: string, params: any) =>
  api().get(`/admin/application/drafts/${applicationUuid}/find-lenders?${qs.stringify(params)}`)

// Create application draft
export const apiCreateAtAdminApplicationDraft = (params: any) =>
  api().postForm("/admin/application/drafts", params)

// Update application draft
export const apiUpdateAtAdminApplicationDraft = (applicationUuid: string, params: any) =>
  api().putForm(`/admin/application/drafts/${applicationUuid}`, params)

// Submit application draft
export const apiSubmitAtAdminApplicationDraft = (applicationUuid: string) =>
  api().patchForm(`/admin/application/drafts/${applicationUuid}`)
