import React from "react"

import { Text, View } from "@react-pdf/renderer"

import { ENTITY_TYPES } from "@repo/util/constant"

interface Props {
  data: Record<string, any>
}

export default function ContentBorrowerDetails(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 18
        }}
      >
        Assets & Liabilities
      </Text>

      <View
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: "1px solid #CDCDCD",
          borderRadius: 8
        }}
      >
        <Text
          style={{
            color: "#0E052B",
            fontWeight: 600,
            fontSize: 16
          }}
        >
          Guarantor Details
        </Text>

        {data?.guarantors.map((item: any) => (
          <View
            key={item.id}
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
              border: "1px solid #CDCDCD",
              borderRadius: 8
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Entity type</Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.entity_type}
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Registered business name
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.registered_business_name}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Australian business number (ABN)
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.australian_business_number}
                </Text>
              </View>

              {(item.entity_type === ENTITY_TYPES.CompanyPrivate.key ||
                item.entity_type === ENTITY_TYPES.CompanyPublic.key ||
                item.entity_type === ENTITY_TYPES.Trust.key) && (
                <View
                  style={{
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "0%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 8
                  }}
                >
                  <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                    Australian company number (ABN)
                  </Text>
                  <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                    {item.australian_company_number}
                  </Text>
                </View>
              )}
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Website</Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.website}
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Entity industry
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.entity_industry}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Full name</Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.full_name}
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Email address
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.email}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Mobile number
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.mobile_number}
                </Text>
              </View>
            </View>
          </View>
        ))}

        <Text
          style={{
            color: "#0E052B",
            fontWeight: 600,
            fontSize: 16
          }}
        >
          Assets
        </Text>

        {data?.assets.map((item: any) => (
          <View
            key={item.id}
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
              border: "1px solid #CDCDCD",
              borderRadius: 8
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  What assets does the borrower own?
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.assets_type}
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Value</Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.assets_value}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Who is the owner of the asset?
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.assets_owner}
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Ownership percentage
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.assets_ownership_percentage}
                </Text>
              </View>
            </View>
          </View>
        ))}

        <Text
          style={{
            color: "#0E052B",
            fontWeight: 600,
            fontSize: 16
          }}
        >
          Liabilities
        </Text>

        {data?.liabilities.map((item: any) => (
          <View
            key={item.id}
            style={{
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
              border: "1px solid #CDCDCD",
              borderRadius: 8
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  What liabilities does the borrower have?
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.liabilities_type}
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Balance / limit owing
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.liabilities_balance}
                </Text>
              </View>
            </View>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 16
              }}
            >
              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  Who is the lender?
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.liabilities_lender}
                </Text>
              </View>

              <View
                style={{
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: "0%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8
                }}
              >
                <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                  What is your borrowers percentage of debt?
                </Text>
                <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {item.liabilities_debt_percentage}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  )
}
