import React, { useState } from "react"

import { clsx } from "clsx"
import { motion } from "framer-motion"
import { MdCheckCircle } from "react-icons/md"
import { Link } from "react-router-dom"
import { toast } from "sonner"

import Logo from "@/components/_layout/components/Logo"
import { IconUserBroker, IconUserIntroducer, IconUserEnterprise } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useSignupStore from "@/stores/useSignupStore"
import { ANIMATION_DURATION } from "@/utils/constants"

import { USER_ROLES } from "@repo/util/constant"

import StageIndicator from "./components/StageIndicator"

interface RoleItemProps {
  icon: React.ReactNode
  title: string
  description: string
  isSelected: boolean
  onClick: () => void
}

function RoleItem(props: Readonly<RoleItemProps>) {
  const { icon, title, description, isSelected, onClick } = props

  return (
    <div
      className={clsx(
        "relative flex cursor-pointer items-center gap-4 rounded-lg border p-4",
        isSelected ? "border-primary" : ""
      )}
      onClick={onClick}
    >
      <div
        className={clsx(
          "flex h-12 max-h-12 min-h-12 w-12 min-w-12 max-w-12 items-center justify-center rounded-full",
          isSelected ? "bg-primary" : "bg-[#CDCDCD]"
        )}
      >
        {icon}
      </div>

      <div className="flex flex-col">
        <div className="text-main mb-1 text-sm font-semibold">{title}</div>
        <p className="text-sm font-normal">{description}</p>
      </div>

      {isSelected && (
        <div className="absolute right-2.5 top-2.5">
          <MdCheckCircle className="text-primary" />
        </div>
      )}
    </div>
  )
}

export default function Step01() {
  const store = useSignupStore()
  const [loading, setLoading] = useState(false)

  const variants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * ANIMATION_DURATION,
        duration: ANIMATION_DURATION
      }
    })
  }

  const roles = [
    {
      role: USER_ROLES.Broker,
      icon: <IconUserBroker className="text-2xl text-white" />,
      title: "I’m a Broker",
      description:
        "I’m setting up as a broker specialising in facilitating commercial finance transactions."
    },
    {
      role: USER_ROLES.Introducer,
      icon: <IconUserIntroducer className="text-2xl text-white" />,
      title: "I’m an Introducer",
      description:
        "I’m seeking to facilitate commercial finance transactions but not directly involved in the brokerage process."
    },
    {
      role: USER_ROLES.Enterprise,
      icon: <IconUserEnterprise className="text-2xl text-white" />,
      title: "I’m an Enterprise",
      description:
        "We’re an organisation with a team of introducers aiming to facilitate commercial finance transactions."
    }
  ]

  const handleRoleClick = (newRole: string) => () => {
    store.updateData({ role: newRole })
  }

  const handleContinueClick = () => {
    setLoading(true)

    if (!store.data.role) {
      toast.error("Please select a role to continue")
      return
    }

    store.goNextStage()
  }

  return (
    <div className="flex h-full w-full flex-col gap-8">
      <motion.div
        custom={0}
        initial="initial"
        animate="animate"
        variants={variants}
        className="flex flex-wrap items-center justify-center gap-4 md:justify-between"
      >
        <Logo />

        <p className="hidden text-sm font-normal md:block">
          Already have an account?{" "}
          <Link className="text-main font-medium" to="/login">
            Log in
          </Link>
        </p>
      </motion.div>

      <div className="flex flex-1 flex-col justify-center">
        <motion.h3
          custom={1}
          initial="initial"
          animate="animate"
          variants={variants}
          className="text-main mb-2.5 text-lg font-semibold"
        >
          Create your free account
        </motion.h3>

        <motion.p
          custom={2}
          initial="initial"
          animate="animate"
          variants={variants}
          className="mb-8 text-sm font-normal"
        >
          Select an option that best describes you.
        </motion.p>

        <div className="mb-8 flex flex-col gap-4">
          {roles.map((r, index) => (
            <motion.div
              key={r.role}
              custom={3 + index}
              initial="initial"
              animate="animate"
              variants={variants}
            >
              <RoleItem
                {...r}
                isSelected={store.data?.role === r.role}
                onClick={handleRoleClick(r.role)}
              />
            </motion.div>
          ))}
        </div>

        <motion.div
          custom={3 + roles.length}
          initial="initial"
          animate="animate"
          variants={variants}
          className="w-full"
        >
          <LoadingButton loading={loading} onClick={handleContinueClick}>
            Continue
          </LoadingButton>
        </motion.div>
      </div>

      <div className="flex flex-col">
        <motion.p
          custom={3 + roles.length + 1}
          initial="initial"
          animate="animate"
          variants={variants}
          className="mb-4 block text-center text-sm font-normal md:hidden"
        >
          Already have an account?{" "}
          <Link className="text-main font-medium" to="/login">
            Log in
          </Link>
        </motion.p>

        <motion.div
          custom={3 + roles.length + 2}
          initial="initial"
          animate="animate"
          variants={variants}
        >
          <StageIndicator />
        </motion.div>
      </div>
    </div>
  )
}
