import qs from "qs"

import api from "./api"

// Get
export const apiGetAdminBorrowersList = (data: any) =>
  api().get(`/admin/borrowers?${qs.stringify(data)}`)
export const apiGetAdminBorrowerByUuid = (uuid: string) => api().get(`/admin/borrowers/${uuid}`)

// Create
export const apiCreateAdminBorrower = (data: any) => api().postForm("/admin/borrowers", data)

// Update
export const apiUpdateAdminBorrower = (uuid: string, data: any) =>
  api().putForm(`/admin/borrowers/${uuid}`, data)

// Archive
export const apiArchiveAdminBorrower = (uuid: string) =>
  api().patch(`/admin/borrowers/${uuid}/archive`)
