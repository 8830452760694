import React from "react"

import { Link, Text, View } from "@react-pdf/renderer"

import { CHECK_ITEM_TYPES } from "@repo/util/constant"

interface Props {
  data: Record<string, any>
}

export default function ContentSupportingDocumentation(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 18
        }}
      >
        Supporting Documents
      </Text>

      <View
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: "1px solid #CDCDCD",
          borderRadius: 8
        }}
      >
        {data?.checkitems?.map((ci: any) => (
          <View key={ci.section} style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            <Text
              style={{
                color: "#0E052B",
                fontWeight: 600,
                fontSize: 16
              }}
            >
              {ci.section}
            </Text>

            {ci.items.map((item: any) => (
              <View
                key={item.text}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 8
                }}
              >
                <Text
                  style={{
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "0%",
                    color: "#0E052B",
                    fontWeight: 300,
                    fontSize: 14
                  }}
                >
                  {item.text}
                </Text>

                {item.type === CHECK_ITEM_TYPES.YesNo && (
                  <Text style={{ color: "#0E052B", fontWeight: 500, fontSize: 14 }}>
                    {item.result}
                  </Text>
                )}

                {item.type === CHECK_ITEM_TYPES.Input && (
                  <Text style={{ color: "#0E052B", fontWeight: 500, fontSize: 14 }}>
                    {item.result}
                  </Text>
                )}

                {item.type === CHECK_ITEM_TYPES.Upload && (
                  <Link
                    src={item.result}
                    style={{ color: "#0E052B", fontWeight: 500, fontSize: 14 }}
                  >
                    Click Here
                  </Link>
                )}
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  )
}
