import { toast } from "sonner"
import { create } from "zustand"

import { apiRegister, apiResendEmail, apiVerifyEmail, apiVerifyMfa } from "@/api/authApi"

import { CLIENT_MSG } from "@repo/i18n"

interface ISignupState {
  loading: boolean
  stage: number
  totalStage: number
  data: Record<string, any>
  qrCodeUri: string
  qrCodeKey: string

  setStage: (payload: number) => void
  goNextStage: () => void
  goPrevStage: () => void

  updateData: (payload: Record<string, any>) => void
  clearData: () => void

  registerAction: (onSuccessCallback?: () => void) => void
  verifyEmailAction: (
    data: any,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => void
  resendEmailAction: (onSuccessCallback?: () => void, onFailureCallback?: () => void) => void
  verifyMfaAction: (
    token: string,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => void
}

const useSignupStore = create<ISignupState>()((set, get) => ({
  hasHydrated: false,
  loading: false,
  stage: 1,
  totalStage: 4,
  data: {},
  qrCodeUri: "",
  qrCodeKey: "",

  setStage: (payload: number) =>
    set({ stage: payload >= 1 && payload <= get().totalStage ? payload : 1 }),
  goNextStage: () =>
    set({ stage: get().stage < get().totalStage ? get().stage + 1 : get().totalStage }),
  goPrevStage: () => set({ stage: get().stage > 1 ? get().stage - 1 : 1 }),

  updateData: (payload: Record<string, any>) => set({ data: { ...get().data, ...payload } }),
  clearData: () => set({ data: {} }),

  registerAction: async (onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiRegister(get().data)

      if (response?.data) {
        const { data, message } = response.data
        set({ data: data })
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyEmailAction: async (data, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyEmail(data)

      if (response?.data) {
        const { message, data } = response.data
        set({ stage: 4 })
        set({ data: data.user })
        set({ qrCodeUri: data.qrCodeUri })
        set({ qrCodeKey: data.qrCodeKey })

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  resendEmailAction: async (onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiResendEmail({ email: get().data.email })

      if (response?.data) {
        const { message } = response.data
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyMfaAction: async (token, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyMfa({
        token,
        user_uuid: get().data.uuid
      })

      if (response.data) {
        const { message } = response.data
        set({ stage: 1 })
        set({ data: {} })
        set({ qrCodeUri: "" })
        set({ qrCodeKey: "" })
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useSignupStore
