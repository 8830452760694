import api from "./api"

/**
 * Login
 */
// Login
export const apiLogin = (data: any) => api().post("/auth/login", data)

// Verify TOTP
export const apiVerifyTotp = (data: any) => api().post("/auth/verify-totp", data)

/**
 * Notifications
 */
// Get notifications
export const apiGetNotification = () => api().get("/auth/notification")

// Read notification
export const apiReadNotification = (id: number) =>
  api().patch(`/auth/notification/${id.toString()}`)

/**
 * Logout
 */
// Logout
export const apiLogout = () => api().post("/auth/logout")

/**
 * Refresh Token
 */
export const apiRefreshToken = () => api().post("/auth/refresh-token")

/**
 * Register
 */
// Register
export const apiRegister = (data: any) => api().post("/auth/register", data)

// Verify Email
export const apiVerifyEmail = (data: any) => api().post("/auth/verify-email", data)

// Resend Email
export const apiResendEmail = (data: any) => api().post("/auth/resend-email", data)

// Verify MFA
export const apiVerifyMfa = (data: any) => api().post("/auth/verify-mfa", data)

/**
 * Reset Password
 */
// Send Password Reset Link
export const apiSendResetLink = (data: any) => api().post("/auth/send-reset-link", data)

// Verify Reset Token
export const apiVerifyResetToken = (data: any) => api().post("/auth/verify-reset-token", data)

// Reset Password
export const apiResetPassword = (data: any) => api().post("/auth/reset-password", data)

/**
 * Update user
 */
export const apiUpdateProfile = (data: any) => api().putForm("/auth/profile", data)

/**
 * Accept invitation
 */
// Verify Invitation
export const apiVerifyInvitation = (data: any) => api().post("/auth/verify-invitation", data)

// Set Invitee Password
export const apiSetInviteePassword = (data: any) => api().post("/auth/set-invitee-password", data)

// Verify Invitee MFA
export const apiVerifyInviteeMfa = (data: any) => api().post("/auth/verify-invitee-mfa", data)
