import React, { ReactNode, useEffect, useState } from "react"

import { IconApplicationSubmitted, IconPurse, IconTag, IconUsers } from "@/components/_icons"

interface ItemProps {
  icon: ReactNode
  count: number
  text: string
}

function TotalStatisticsItem(props: Readonly<ItemProps>) {
  const { icon, count, text } = props

  return (
    <div className="flex flex-1 gap-4 rounded-2xl border border-[#ECECEC] bg-white p-4">
      {icon}

      <div className="flex flex-col gap-1">
        <h2 className="text-main text-2xl font-semibold">{count}</h2>
        <p className="text-default text-sm font-normal">{text}</p>
      </div>
    </div>
  )
}

interface Props {
  data: any
}

export default function TotalStatistics(props: Readonly<Props>) {
  const { data } = props

  const [items, setItems] = useState<any[]>([])

  useEffect(() => {
    const newItems = []
    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#EE53CC33]">
          <IconUsers className="text-lg text-[#EE53CC]" />
        </div>
      ),
      count: data?.users ?? 0,
      text: "Total Users"
    })

    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#F5BC2733]">
          <IconApplicationSubmitted className="text-2xl text-[#F5BC27]" />
        </div>
      ),
      count: data?.applications ?? 0,
      text: "Total Applications"
    })

    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#379FFF33]">
          <IconPurse className="text-2xl text-[#379FFF]" />
        </div>
      ),
      count: data?.commissions_paid ?? 0,
      text: "Total Commissions Paid"
    })

    newItems.push({
      icon: (
        <div className="flex max-h-14 min-h-14 min-w-14 max-w-14 items-center justify-center rounded-full bg-[#08ECB333]">
          <IconTag className="text-2xl text-[#08ECB3]" />
        </div>
      ),
      count: data?.settled_deals ?? 0,
      text: "Total Settled Deals"
    })

    setItems(newItems)
  }, [data])

  return (
    <div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
      {items.map((i) => (
        <TotalStatisticsItem key={i.text} {...i} />
      ))}
    </div>
  )
}
