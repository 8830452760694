import qs from "qs"

import api from "./api"

// Get
export const apiGetBusinessUserList = (data: any) =>
  api().get(`/business/users?${qs.stringify(data)}`)
export const apiGetBusinessUserByUuid = (uuid: string) => api().get(`/business/users/${uuid}`)

// Create
export const apiCreateBusinessUser = (data: any) => api().postForm("/business/users", data)

// Update
export const apiUpdateBusinessUser = (uuid: string, data: any) =>
  api().putForm(`/business/users/${uuid}`, data)

// Archive
export const apiArchiveBusinessUser = (uuid: string) =>
  api().patch(`/business/users/${uuid}/archive`)
