import React, { useCallback, useEffect, useState } from "react"

import json2mq from "json2mq"
import { Link } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

import AdminLayout from "@/components/_layout/AdminLayout"
import { ITableParams } from "@/types/interface"
import useAdminBorrowerStore from "@/stores/useAdminBorrowerStore"

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from "@repo/ui/components/ui/breadcrumb"
import { ALL, BORROWER_STATUS } from "@repo/util/constant"

import columns from "./DataTable/columns"
import DataTable from "./DataTable"

export default function AdminBorrowersList() {
  const store = useAdminBorrowerStore()

  const [data, setData] = useState<any[]>([])
  const [totalCount, setTotalCount] = useState(0)

  const [tableParams, setTableParams] = useState<ITableParams>({
    isMobile: false,
    filter: {
      status: BORROWER_STATUS.ACTIVE,
      entity_name: "",
      entity_type: ALL,
      australian_business_number: ""
    },
    sort: {
      column: "entity_name",
      direction: "asc"
    },
    pagination: {
      page_index: 1,
      page_size: 10
    }
  })

  const isMobile = useMediaQuery({ query: json2mq({ maxWidth: 767 }) })

  const initialize = useCallback(() => {
    store.getListAction({ ...tableParams }, (newData, newTotalCount) => {
      setTotalCount(newTotalCount)
      setData(newData)
    })
  }, [tableParams, store.reloadRequired])

  useEffect(() => {
    initialize()
  }, [initialize])

  useEffect(() => {
    if (isMobile !== tableParams.isMobile) {
      setTableParams((prev: ITableParams) => ({
        ...prev,
        isMobile,
        pagination: {
          ...prev.pagination,
          page_size: isMobile ? -1 : 10
        }
      }))
    }
  }, [isMobile])

  return (
    <AdminLayout
      breadcrumb={
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <Link to="/admin/dashboard" className="text-default text-sm font-normal">
                Dashboard
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className="text-main text-sm font-semibold">Borrowers</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      }
    >
      <DataTable
        loading={store.loading}
        columns={columns}
        data={data}
        totalCount={totalCount}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
    </AdminLayout>
  )
}
