import React from "react"

import { IconPercent } from "@/components/_icons"
import clsx from "clsx"
import { MdCheckCircle } from "react-icons/md"

interface Props {
  isSelected: boolean
  value: number
  description: string
  onClick: () => void
}

export default function CommissionSplitItem(props: Readonly<Props>) {
  const { isSelected, value, description, onClick } = props

  return (
    <div
      className={clsx(
        "relative flex w-full flex-1 cursor-pointer flex-col items-center rounded-2xl border p-8",
        isSelected ? "border-primary" : ""
      )}
      onClick={onClick}
    >
      {isSelected && (
        <div className="absolute right-3 top-3">
          <MdCheckCircle className="text-primary" />
        </div>
      )}

      <div
        className={clsx(
          "mb-4 flex h-16 w-16 items-center justify-center rounded-full",
          isSelected ? "bg-primary" : "bg-[#CDCDCD]"
        )}
      >
        <IconPercent className="text-2xl text-white" />
      </div>

      <div
        className={clsx(
          "mb-2 text-center text-base font-semibold",
          isSelected ? "text-main" : "text-default"
        )}
      >
        {value * 100}/{Math.round((1 - value) * 100)} Commission Split
      </div>

      <div className="text-default text-center text-sm font-normal">{description}</div>
    </div>
  )
}
