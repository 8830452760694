import React, { useEffect, useRef } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useBusinessSettingStore from "@/stores/useBusinessSettingStore"

import { Button } from "@repo/ui/components/ui/button"
import { ENTITY_TYPES } from "@repo/util/constant"

import EntityDetails from "./sections/EntityDetails"
import Address from "./sections/Address"
import Membership from "./sections/Membership"
import Aggregator from "./sections/Aggregator"
import AustralianCreditLicence from "./sections/AustralianCreditLicence"
import AustralianCreditRepresentative from "./sections/AustralianCreditRepresentative"
import ProfessionalIndemnityInsurance from "./sections/ProfessionalIndemnityInsurance"
import Points from "./sections/Points"

const FormSchema = z
  .object({
    entity_type: z.string().min(1, { message: "Entity type is required" }),
    temp_abn: z.string().min(1, { message: "Temporarily ABN is required" }),
    registered_business_name: z
      .string()
      .min(1, { message: "Registered business name is required" }),
    australian_business_number: z
      .string()
      .min(1, { message: "Australian business number is required" }),
    australian_company_number: z.string(),
    entity_email: z.string().min(1, { message: "Entity email address is required" }).email({
      message: "Please enter a valid email address"
    }),
    entity_contact_number: z.string().min(1, { message: "Entity contact number is required" }),
    website: z.string().min(1, { message: "Website is required" }).url({
      message: "Please enter a valid website url"
    }),
    entity_industry: z.string().min(1, { message: "Entity industry is required" }),

    street_address: z.string().min(1, { message: "Street address is required" }),
    suburb: z.string().min(1, { message: "Suburb is required" }),
    state: z.string().min(1, { message: "State is required" }),
    postcode: z.string().min(1, { message: "Postcode is required" }),

    membership_association: z.string().min(1, { message: "Association is required" }),
    membership_certificate: z
      .string()
      .min(1, { message: "Membership Certificate Number is required" }),
    membership_expiry: z.coerce.date(),

    aggregator: z.string().min(1, { message: "This field is required" }),

    acl_certificate: z.string().min(1, { message: "Australian Credit Licence Number is required" }),
    acl_expiry: z.coerce.date(),

    acr_certificate: z
      .string()
      .min(1, { message: "Australian Credit Representative Number is required" }),
    acr_expiry: z.coerce.date(),

    pii_certificate: z.string().min(1, { message: "Insurance Policy Number is required" }),
    pii_expiry: z.coerce.date()
  })
  .refine(
    (data) => {
      if (
        (data.entity_type === ENTITY_TYPES.CompanyPrivate.key && !data.australian_company_number) ||
        (data.entity_type === ENTITY_TYPES.CompanyPublic.key && !data.australian_company_number)
      ) {
        return false
      }
      return true
    },
    {
      message: "Australian company number is required",
      path: ["australian_company_number"]
    }
  )

type FormSchemaType = z.infer<typeof FormSchema>

export default function MainForm() {
  const store = useBusinessSettingStore()
  const navigate = useNavigate()
  const initialized = useRef(false)

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 1 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 1 })
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 1 })
  const { ref: ref5, inView: inView5 } = useInView({ threshold: 1 })
  const { ref: ref6, inView: inView6 } = useInView({ threshold: 1 })
  const { ref: ref7, inView: inView7 } = useInView({ threshold: 1 })
  const { ref: ref8, inView: inView8 } = useInView({ threshold: 1 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      entity_type: "",
      temp_abn: "",
      registered_business_name: "",
      australian_business_number: "",
      australian_company_number: "",
      entity_email: "",
      entity_contact_number: "",
      website: "",
      entity_industry: "",

      street_address: "",
      suburb: "",
      state: "",
      postcode: "",

      membership_association: "",
      membership_certificate: "",
      membership_expiry: undefined,

      aggregator: "",

      acl_certificate: "",
      acl_expiry: undefined,

      acr_certificate: "",
      acr_expiry: undefined,

      pii_certificate: "",
      pii_expiry: undefined
    }
  })

  const initialize = () => {
    store.getAction((data) => {
      form.reset({
        entity_type: data.entity_type ?? "",
        temp_abn: data.australian_business_number ?? "",
        registered_business_name: data.registered_business_name ?? "",
        australian_business_number: data.australian_business_number ?? "",
        australian_company_number: data.australian_company_number ?? "",
        entity_email: data.entity_email ?? "",
        entity_contact_number: data.entity_contact_number ?? "",
        website: data.website ?? "",
        entity_industry: data.entity_industry ?? "",

        street_address: data.street_address ?? "",
        suburb: data.suburb ?? "",
        state: data.state ?? "",
        postcode: data.postcode ?? "",

        membership_association: data?.membership_association ?? "",
        membership_certificate: data?.membership_certificate ?? "",
        membership_expiry: data?.membership_expiry ?? undefined,

        aggregator: data.aggregator ?? "",

        acl_certificate: data?.acl_certificate ?? "",
        acl_expiry: data?.acl_expiry ?? undefined,

        acr_certificate: data?.acr_certificate ?? "",
        acr_expiry: data?.acr_expiry ?? undefined,

        pii_certificate: data?.pii_certificate ?? "",
        pii_expiry: data?.pii_expiry ?? undefined
      })
    })
  }

  const handleCancelClick = () => {
    navigate("/dashboard")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(values)
  }

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true
      initialize()
    }
  }, [])

  return (
    <div className="flex flex-col">
      <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
        <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
          <FormProvider {...form}>
            <form className="flex w-full flex-col gap-5" onSubmit={form.handleSubmit(onSubmit)}>
              <div ref={ref1} id="entity-details">
                <EntityDetails />
              </div>

              <div ref={ref2} id="business-address">
                <Address />
              </div>

              <div ref={ref3} id="membership">
                <Membership />
              </div>

              <div ref={ref4} id="aggregator">
                <Aggregator />
              </div>

              <div ref={ref5} id="australian-credit-licence">
                <AustralianCreditLicence />
              </div>

              <div ref={ref6} id="australian-credit-representative">
                <AustralianCreditRepresentative />
              </div>

              <div ref={ref7} id="professional-indemnity-insurance">
                <ProfessionalIndemnityInsurance />
              </div>
            </form>
          </FormProvider>

          <div ref={ref8} id="points">
            <Points />
          </div>
        </div>

        <StickyShortcut
          items={[
            {
              id: "entity-details",
              label: "Entity Details",
              inView: inView1
            },
            {
              id: "business-address",
              label: "Business Address",
              inView: inView2
            },
            {
              id: "membership",
              label: "Membership",
              inView: inView3
            },
            {
              id: "aggregator",
              label: "Aggregator",
              inView: inView4
            },
            {
              id: "australian-credit-licence",
              label: "Australian Credit Licence (ACL)",
              inView: inView5
            },
            {
              id: "australian-credit-representative",
              label: "Australian Credit Representative",
              inView: inView6
            },
            {
              id: "professional-indemnity-insurance",
              label: "Professional Indemnity Insurance",
              inView: inView7
            },
            {
              id: "points",
              label: "100 Points of ID",
              inView: inView8
            }
          ]}
        />
      </div>

      <div className="flex w-full justify-end gap-2 pb-2 pt-6">
        <Button
          type="button"
          className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
          onClick={handleCancelClick}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={store.loading}
          type="button"
          className="px-6 py-4 md:!w-auto"
          onClick={form.handleSubmit(onSubmit)}
        >
          Save
        </LoadingButton>
      </div>
    </div>
  )
}
