import React from "react"

import clsx from "clsx"
import { format } from "date-fns"
import { useFormContext } from "react-hook-form"

import { IconCalendar } from "@/components/_icons"

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"

import { MEMBERSHIP_ASSOCIATIONS } from "@repo/util/constant"

export default function Membership() {
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Membership</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="membership_association"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Association</FormLabel>
                <Select onValueChange={field.onChange} value={field.value} disabled>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {MEMBERSHIP_ASSOCIATIONS.map((ma) => (
                      <SelectItem key={ma} value={ma}>
                        {ma}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="membership_certificate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Membership Certificate Number
                </FormLabel>
                <Input disabled {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="membership_expiry"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel className="text-main mb-1 text-sm font-normal">Expiry date</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input
                      className="cursor-pointer"
                      disabled
                      value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                    />
                    <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                      <IconCalendar className="text-default text-lg" />
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
