import { toast } from "sonner"
import { create } from "zustand"

import { apiGetBusinessSetting, apiUpdateBusinessSetting } from "@/api/businessSettingsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IBusinessSettingStore {
  loading: boolean

  getAction: (onSuccessCallback?: (data: any) => void) => void
  updateAction: (data: any, onSuccessCallback?: () => void) => void
}

const useBusinessSettingStore = create<IBusinessSettingStore>()((set, get) => ({
  loading: false,

  getAction: async (onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetBusinessSetting()

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateAction: async (data: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateBusinessSetting(data)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useBusinessSettingStore
