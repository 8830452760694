import React, { useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import clsx from "clsx"
import { format } from "date-fns"
import { useForm } from "react-hook-form"
import { NumericFormat } from "react-number-format"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconCalendar, IconNext } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import { Button } from "@repo/ui/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { Calendar } from "@repo/ui/components/ui/calendar"
import { EXIT_STRATEGIES, LOAN_TERMS } from "@repo/util/constant"

import { LOADING_TIMEOUT } from "../../util"
import { useAppContext } from "../../AppContext"

const FormSchema = z.object({
  loan_amount: z.coerce.number({ invalid_type_error: "Please input your loan amount" }).positive(),
  project_end_value: z.coerce
    .number({ invalid_type_error: "Please input your project end value" })
    .positive(),
  loan_term: z
    .string({ required_error: "Please input your loan term" })
    .min(1, { message: "Please input your loan term" }),
  loan_by: z.date({
    required_error: "Please choose your loan by date",
    invalid_type_error: "Please choose your loan by date"
  }),
  exit_strategy: z.string().min(1, { message: "Exit strategy is required" })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function LoanDetails() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAppContext()
  const navigate = useNavigate()
  const store = useApplicationDraftStore()

  const [openCalendar, setOpenCalendar] = useState(false)

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      loan_amount: undefined,
      project_end_value: undefined,
      loan_term: undefined,
      loan_by: undefined,
      exit_strategy: ""
    }
  })

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          loan_amount: form.getValues("loan_amount") || null,
          project_end_value: form.getValues("project_end_value") || null,
          loan_term: form.getValues("loan_term") || null,
          loan_by: form.getValues("loan_by") || null,
          exit_strategy: form.getValues("exit_strategy") || null,
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          loan_amount: form.getValues("loan_amount") || null,
          project_end_value: form.getValues("project_end_value") || null,
          loan_term: form.getValues("loan_term") || null,
          loan_by: form.getValues("loan_by") || null,
          exit_strategy: form.getValues("exit_strategy") || null,
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      loan_amount: data?.loan_amount,
      project_end_value: data?.project_end_value,
      loan_term: data?.loan_term,
      loan_by: data?.loan_by ? new Date(data?.loan_by) : undefined,
      exit_strategy: data?.exit_strategy
    })
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-[352px]">
            <h3 className="text-main mb-2 text-lg font-semibold">Loan Details</h3>

            <p className="text-default mb-4 text-sm font-normal">Please enter loan details.</p>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="loan_amount"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Loan amount <span className="text-default italic">required</span>
                      </FormLabel>
                      <NumericFormat
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        placeholder="$"
                        getInputRef={field.ref}
                        name={field.name}
                        value={field.value ?? ""}
                        onBlur={field.onBlur}
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value)
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="project_end_value"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Project End Value <span className="text-default italic">required</span>
                      </FormLabel>
                      <NumericFormat
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        placeholder="$"
                        getInputRef={field.ref}
                        name={field.name}
                        value={field.value ?? ""}
                        onBlur={field.onBlur}
                        onValueChange={(values, sourceInfo) => {
                          field.onChange(values.value)
                        }}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="loan_term"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Loan term <span className="text-default italic">required</span>
                      </FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            field.value ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(LOAN_TERMS).map(([k, v]) => (
                            <SelectItem key={k} value={v.key.toString()}>
                              {v.text}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="loan_by"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        When do you require the loan by?{" "}
                        <span className="text-default italic">required</span>
                      </FormLabel>
                      <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <div className="relative">
                              <Input
                                className="cursor-pointer"
                                readOnly
                                value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                              />
                              <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                                <IconCalendar className="text-default text-lg" />
                              </div>
                            </div>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <Calendar
                            initialFocus
                            mode="single"
                            selected={field.value}
                            onSelect={(date) => {
                              field.onChange(date)
                              setOpenCalendar(false)
                            }}
                            disabled={(date) => date < new Date("1900-01-01")}
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="exit_strategy"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        What is your exit strategy?
                      </FormLabel>
                      <Select onValueChange={field.onChange} value={field.value}>
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            field.value ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(EXIT_STRATEGIES).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="hidden flex-1 md:block"></div>
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.loading}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
