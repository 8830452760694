import React from "react"

import { motion, AnimatePresence } from "framer-motion"

import { LoadingSpinner } from "@/components/_uiext"

import { APPLICATION_STEPS } from "@repo/util/constant"

import BorrowerSelection from "./sections/BorrowerSelection"
import CommissionSplit from "./sections/CommissionSplit"
import ApplicantType from "./sections/ApplicantType"
import FundingSolution from "./sections/FundingSolution"
import LoanType from "./sections/LoanType"
import LoanDetails from "./sections/LoanDetails"
import SecurityDetails from "./sections/SecurityDetails"
import LenderSelection from "./sections/LenderSelection"
import BorrowerDetails from "./sections/BorrowerDetails"
import SupportingDocumentation from "./sections/SupportingDocumentation"
import ReviewApplication from "./sections/ReviewApplication"

import { CREATE_MOTION_VARIANT } from "../util"
import { useAdminAppContext } from "../AdminAppContext"

export default function MainForm() {
  const { loading, steps, activeStep } = useAdminAppContext()

  return (
    <LoadingSpinner loading={loading}>
      <div className="flex flex-col">
        <AnimatePresence>
          {steps[activeStep] === APPLICATION_STEPS.BorrowerSelection && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <BorrowerSelection />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.CommissionSplit && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <CommissionSplit />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.ApplicantType && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <ApplicantType />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.FundingSolution && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <FundingSolution />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.LoanType && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <LoanType />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.LoanDetails && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <LoanDetails />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.SecurityDetails && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <SecurityDetails />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.LenderSelection && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <LenderSelection />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.AssetsLiabilities && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <BorrowerDetails />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.SupportingDocuments && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <SupportingDocumentation />
            </motion.div>
          )}

          {steps[activeStep] === APPLICATION_STEPS.ReviewApplication && (
            <motion.div
              key={steps[activeStep]}
              variants={CREATE_MOTION_VARIANT}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <ReviewApplication />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </LoadingSpinner>
  )
}
