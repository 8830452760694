import React from "react"

import clsx from "clsx"
import { useFieldArray, useFormContext } from "react-hook-form"
import { NumericFormat } from "react-number-format"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  AUSTRALIA_STATES,
  COUNTRIES,
  ENTITY_TYPES,
  EXIT_STRATEGIES,
  LOAN_DOCUMENT_TYPES,
  LOAN_TERMS,
  NEWZEALAND_STATES,
  NON_SPECIALISED_DEVELOPMENTS,
  REGIONS,
  REPAYMENTS,
  SPECIALISED_DEVELOPMENTS
} from "@repo/util/constant"

import CheckItems from "./CheckItems"

interface Props {
  fsIndex: number
}

export default function LoanTypes(props: Readonly<Props>) {
  const { fsIndex } = props
  const form = useFormContext()

  const { fields } = useFieldArray({
    control: form.control,
    name: `fs.${fsIndex}.lt`
  })

  return (
    <div className="flex flex-col gap-4">
      {fields.map((item: any, index) => (
        <div key={item.id} className="rounded-xl border p-4">
          <h4 className="text-main mb-4 text-base font-semibold">
            Loan Type: <span className="text-primary">{item.loan_type}</span>
          </h4>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.applicant_types`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Applicant Type</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v.key)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v.key])
                              } else {
                                field.onChange(
                                  updatedValue.filter((item: string) => item !== v.key)
                                )
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v.key}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.loan_document_types`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">
                    Loan Document Type
                  </FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(LOAN_DOCUMENT_TYPES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v.key)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v.key])
                              } else {
                                field.onChange(
                                  updatedValue.filter((item: string) => item !== v.key)
                                )
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v.key}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.minimum_loan_size`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Minimum loan size
                    </FormLabel>
                    <NumericFormat
                      disabled
                      customInput={Input}
                      thousandSeparator
                      prefix="$"
                      // {...field}
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.maximum_loan_size`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Maximum loan size
                    </FormLabel>
                    <NumericFormat
                      disabled
                      customInput={Input}
                      thousandSeparator
                      prefix="$"
                      // {...field}
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.is_higher_loan_amount`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-medium">
                        Higher loan amount
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="hidden flex-1 md:block"></div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.minimum_loan_term`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Minimum loan term
                    </FormLabel>
                    <Select disabled onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger
                        className={clsx(
                          "h-12 w-full text-sm",
                          field.value ? "text-main" : "text-default"
                        )}
                      >
                        <SelectValue placeholder="Please select" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(LOAN_TERMS).map(([k, v]) => (
                          <SelectItem key={k} value={v.key.toString()}>
                            {v.text}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.maximum_loan_term`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Maximum loan term
                    </FormLabel>
                    <Select disabled onValueChange={field.onChange} value={field.value}>
                      <SelectTrigger
                        className={clsx(
                          "h-12 w-full text-sm",
                          field.value ? "text-main" : "text-default"
                        )}
                      >
                        <SelectValue placeholder="Please select" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(LOAN_TERMS).map(([k, v]) => (
                          <SelectItem key={k} value={v.key.toString()}>
                            {v.text}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.loan_value_ratio`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Loan value ratio
                    </FormLabel>
                    <NumericFormat
                      disabled
                      customInput={Input}
                      thousandSeparator
                      prefix="%"
                      decimalScale={2}
                      fixedDecimalScale
                      // {...field}
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="hidden flex-1 md:block"></div>
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.exit_strategies`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Exit Strategy</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(EXIT_STRATEGIES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.nsd`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">
                    Non-Specialised Development
                  </FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(NON_SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.sd`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">
                    Specialised Development
                  </FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.countries`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Country</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.entries(COUNTRIES).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.states`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">States</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    <div className="flex flex-col gap-1">
                      {AUSTRALIA_STATES.map((v) => (
                        <FormItem key={v} className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <Checkbox
                              disabled
                              checked={field.value?.includes(v)}
                              onCheckedChange={(checked) => {
                                const updatedValue = field.value || []
                                if (checked) {
                                  field.onChange([...updatedValue, v])
                                } else {
                                  field.onChange(updatedValue.filter((item: string) => item !== v))
                                }
                              }}
                              className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                            />
                          </FormControl>
                          <FormLabel className="text-main font-normal">{v}</FormLabel>
                        </FormItem>
                      ))}
                    </div>

                    <div className="flex flex-col gap-1">
                      {NEWZEALAND_STATES.map((v) => (
                        <FormItem key={v} className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <Checkbox
                              disabled
                              checked={field.value?.includes(v)}
                              onCheckedChange={(checked) => {
                                const updatedValue = field.value || []
                                if (checked) {
                                  field.onChange([...updatedValue, v])
                                } else {
                                  field.onChange(updatedValue.filter((item: string) => item !== v))
                                }
                              }}
                              className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                            />
                          </FormControl>
                          <FormLabel className="text-main font-normal">{v}</FormLabel>
                        </FormItem>
                      ))}
                    </div>
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.regions`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Region</FormLabel>
                  <div className="grid grid-cols-1 gap-2">
                    {Object.entries(REGIONS).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 w-full">
            <FormField
              control={form.control}
              name={`fs.${fsIndex}.lt.${index}.repayments`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-sm font-medium">Repayment</FormLabel>
                  <div className="grid grid-cols-1 gap-2">
                    {Object.entries(REPAYMENTS).map(([k, v]) => (
                      <FormItem key={k} className="flex items-center space-x-2 space-y-0">
                        <FormControl>
                          <Checkbox
                            disabled
                            checked={field.value?.includes(v)}
                            onCheckedChange={(checked) => {
                              const updatedValue = field.value || []
                              if (checked) {
                                field.onChange([...updatedValue, v])
                              } else {
                                field.onChange(updatedValue.filter((item: string) => item !== v))
                              }
                            }}
                            className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                          />
                        </FormControl>
                        <FormLabel className="text-main font-normal">{v}</FormLabel>
                      </FormItem>
                    ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.establishment_fee`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">
                      Establishment Fee
                    </FormLabel>
                    <NumericFormat
                      disabled
                      customInput={Input}
                      thousandSeparator
                      prefix="%"
                      decimalScale={2}
                      fixedDecimalScale
                      // {...field}
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.interest_rate`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-main text-sm font-medium">Interest Rate</FormLabel>
                    <NumericFormat
                      disabled
                      customInput={Input}
                      thousandSeparator
                      prefix="%"
                      decimalScale={2}
                      fixedDecimalScale
                      // {...field}
                      getInputRef={field.ref}
                      name={field.name}
                      value={field.value ?? ""}
                      onBlur={field.onBlur}
                      onValueChange={(values, sourceInfo) => {
                        field.onChange(values.value)
                      }}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.mortgage_management_fee`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-medium">
                        Mortgage Management Fee
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.line_fee`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-medium">Line Fee</FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>

          <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
            <div className="flex-1">
              <FormField
                control={form.control}
                name={`fs.${fsIndex}.lt.${index}.pre_sales_requirement`}
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        disabled
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-medium">
                        Pre-Sales Requirement
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="hidden flex-1 md:block"></div>
          </div>

          <CheckItems fsIndex={fsIndex} ltIndex={index} />
        </div>
      ))}
    </div>
  )
}
