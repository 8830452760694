import React, { useEffect, useState } from "react"

import clsx from "clsx"
import { format } from "date-fns"
import { LuChevronDown, LuChevronUp } from "react-icons/lu"
import { Link, useNavigate } from "react-router-dom"

import { IconNotification, IconAccountSettings, IconLogOut } from "@/components/_icons"
import useAuthStore from "@/stores/useAuthStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { NOTIFICATION_TYPES, USER_ROLES } from "@repo/util/constant"

import Logo from "../components/Logo"
import Drawer from "./Drawer"
import AdminDrawer from "./AdminDrawer"

interface Props {
  breadcrumb: React.ReactNode
}

export default function Header({ breadcrumb }: Readonly<Props>) {
  const authStore = useAuthStore()
  const navigate = useNavigate()

  const [openDrawer, setOpenDrawer] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notifications, setNotifications] = useState<any[]>([])

  const initialize = () => {
    authStore.getNotificationAction((newData) => {
      setNotifications(newData)
    })
  }

  const handleLogoutClick = () => {
    authStore.logoutAction()
  }

  const handleNotificationItemClick = (notification: any) => () => {
    authStore.readNotificationAction(notification.id, () => {
      if (notification.meta.type === NOTIFICATION_TYPES.APPLICATION) {
        let urlPrefix = "/applications/view/"

        if (authStore.user?.role === USER_ROLES.Administrator) {
          urlPrefix = "/admin/applications/view/"
        }

        navigate(urlPrefix + notification.meta.uuid)
        setOpenNotification(false)
      }

      if (notification.meta.type === NOTIFICATION_TYPES.IntroducerLead) {
        let urlPrefix = "/leads/view/"

        if (authStore.user?.role === USER_ROLES.Administrator) {
          urlPrefix = "/admin/leads/view/"
        }

        navigate(urlPrefix + notification.meta.uuid, { replace: true })
        setOpenNotification(false)
      }

      const updatedNotifications = notifications.map((n) => {
        if (n.id === notification.id) {
          return { ...n, is_read: true }
        }
        return n
      })
      setNotifications(updatedNotifications)
    })
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <>
      <header className="fixed left-0 right-0 top-0 z-50 hidden h-20 items-center justify-between bg-white px-4 py-3 md:left-[270px] lg:flex">
        {breadcrumb}

        <div className="flex items-center justify-center gap-4">
          <Popover open={openNotification} onOpenChange={setOpenNotification}>
            <PopoverTrigger className="relative">
              <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#F8F8F8]">
                <IconNotification className="text-main text-2xl" />
              </div>

              {notifications.filter((n) => !n.is_read).length > 0 && (
                <div className="absolute bottom-1 right-1 flex h-4 w-4 items-center justify-center rounded-full bg-[#ef4444] text-[10px] font-semibold text-white">
                  {notifications.filter((n) => !n.is_read).length}
                </div>
              )}
            </PopoverTrigger>

            <PopoverContent
              align="end"
              side="bottom"
              sideOffset={20}
              className="flex flex-col gap-4"
            >
              {notifications.length === 0 && (
                <div className="text-default text-center text-sm font-normal">
                  You have no new notifications.
                </div>
              )}

              {notifications.length > 0 &&
                notifications.map((n) => (
                  <div
                    key={n.id}
                    className="flex cursor-pointer items-center justify-between"
                    onClick={handleNotificationItemClick(n)}
                  >
                    <div className="flex items-center">
                      <Avatar className="mr-3 h-12 w-12">
                        <AvatarImage
                          className="h-12 w-12 rounded-full object-cover"
                          src={n.sender?.image}
                          alt={`${n.sender?.first_name} ${n.sender?.last_name}`}
                        />
                        <AvatarFallback className="uppercase">
                          {n.sender?.first_name?.[0]}
                          {n.sender?.last_name?.[0]}
                        </AvatarFallback>
                      </Avatar>

                      <div className="mr-1 flex flex-col gap-1">
                        <div
                          className="text-main text-sm font-normal"
                          dangerouslySetInnerHTML={{ __html: n.title }}
                        />

                        <div className="text-default text-[10px] font-normal">
                          {format(n.created_at, "MMM dd")} | {format(n.created_at, "h:mm aaa")}
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "min-h-2 min-w-2 rounded-full",
                        !n.is_read ? "bg-[#ef4444]" : ""
                      )}
                    ></div>
                  </div>
                ))}
            </PopoverContent>
          </Popover>

          <DropdownMenu open={openDrawer} onOpenChange={setOpenDrawer}>
            <DropdownMenuTrigger className="outline-none">
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-2">
                  <Avatar>
                    <AvatarImage
                      className="h-10 w-10 rounded-full object-cover"
                      src={authStore.user?.image}
                      alt={`${authStore.user?.first_name} ${authStore.user?.last_name}`}
                    />
                    <AvatarFallback className="uppercase">
                      {authStore.user?.first_name?.[0]}
                      {authStore.user?.last_name?.[0]}
                    </AvatarFallback>
                  </Avatar>

                  <div className="flex flex-col">
                    <p className="text-main text-left text-sm font-semibold">
                      {authStore.user?.first_name} {authStore.user?.last_name}
                    </p>
                  </div>
                </div>

                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#F8F8F8]">
                  {openDrawer ? (
                    <LuChevronUp className="text-default text-xl" />
                  ) : (
                    <LuChevronDown className="text-default text-xl" />
                  )}
                </div>
              </div>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="flex w-60 flex-col gap-2 p-3" sideOffset={20}>
              <DropdownMenuItem asChild className="flex items-center gap-1">
                <Link className="text-default text-sm" to="/account-settings">
                  <IconAccountSettings className="text-xl" />
                  Account Settings
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={handleLogoutClick}
                className="flex items-center gap-1 text-[#EF4444] hover:!text-[#EF4444]"
              >
                <IconLogOut className="text-xl" />
                Log Out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </header>

      <header className="fixed left-0 right-0 z-50 flex h-[104px] w-full items-center justify-between bg-white px-6 py-8 lg:hidden">
        <Logo />

        {authStore.user?.is_admin ? <AdminDrawer /> : <Drawer />}
      </header>
    </>
  )
}
