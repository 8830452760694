import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

import { NumericFormat } from "react-number-format"

import { IconCalendar } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useAdminApplicationStore from "@/stores/useAdminApplicationStore"

import { Calendar } from "@repo/ui/components/ui/calendar"
import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { FormControl } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"

import { useAdminAppContext } from "../../AdminAppContext"
import { format } from "date-fns"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function EarningsDialog(props: Readonly<Props>) {
  const { open, setOpen } = props
  const store = useAdminApplicationStore()
  const { data, setData } = useAdminAppContext()

  const [openCalendar, setOpenCalendar] = useState(false)
  const [settledAmount, setSettledAmount] = useState("")
  const [commissionsEarned, setCommissionsEarned] = useState("")
  const [earningDate, setEarningDate] = useState<any>(undefined)

  const handleSaveClick = () => {
    store.updateEarningAction(
      data.uuid,
      {
        settled_amount: settledAmount,
        commissions_earned: commissionsEarned,
        earning_date: earningDate
      },
      (newData) => {
        setData(newData)
        setOpen(false)
      }
    )
  }

  useEffect(() => {
    setSettledAmount(data?.settled_amount)
    setCommissionsEarned(data?.commissions_earned)
    setEarningDate(data?.earning_date)
  }, [data])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex h-full flex-col justify-between gap-0 bg-white p-10 md:h-auto">
        <div className="flex flex-col">
          <h1 className="text-main mb-4 text-center text-lg font-semibold">Earnings</h1>

          <p className="text-default mb-6 text-center text-base font-normal">
            Please enter the commission earned, settled amount and date for Application{" "}
            <span className="text-main">
              #
              {Number(data?.id ?? "")
                .toString()
                .padStart(10, "0")}
            </span>
          </p>

          <p className="text-main mb-2 text-sm font-normal">Settled Amount</p>
          <NumericFormat
            className="mb-6"
            customInput={Input}
            thousandSeparator
            prefix="$"
            value={settledAmount}
            onValueChange={(values, sourceInfo) => {
              setSettledAmount(values.value)
            }}
          />

          <p className="text-main mb-2 text-sm font-normal">Commissions Earned</p>
          <NumericFormat
            className="mb-6"
            customInput={Input}
            thousandSeparator
            prefix="$"
            value={commissionsEarned}
            onValueChange={(values, sourceInfo) => {
              setCommissionsEarned(values.value)
            }}
          />

          <p className="text-main mb-2 text-sm font-normal">Date</p>
          <Popover open={openCalendar} onOpenChange={setOpenCalendar}>
            <PopoverTrigger asChild className="mb-6">
              <FormControl>
                <div className="relative">
                  <Input
                    className="cursor-pointer"
                    readOnly
                    value={earningDate ? format(earningDate, "dd/MM/yyyy") : ""}
                  />
                  <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                    <IconCalendar className="text-default text-lg" />
                  </div>
                </div>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="single"
                selected={earningDate ?? ""}
                onSelect={(date) => {
                  setEarningDate(date)
                  setOpenCalendar(false)
                }}
                disabled={(date) => date < new Date("1900-01-01")}
              />
            </PopoverContent>
          </Popover>
        </div>

        <div className="flex justify-center">
          <LoadingButton
            loading={store.loading}
            className="w-full px-6 py-4 md:w-auto"
            onClick={handleSaveClick}
          >
            Submit
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}
