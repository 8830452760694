import React from "react"

import { NumericFormat } from "react-number-format"

import { useAdminAppContext } from "@/pages/admin/applications/AdminAppContext"

import { FormControl, FormItem, FormLabel } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import { NON_SPECIALISED_DEVELOPMENTS, SPECIALISED_DEVELOPMENTS } from "@repo/util/constant"

export default function Default() {
  const { data } = useAdminAppContext()

  return (
    <div className="flex w-full flex-col gap-4 rounded-2xl border p-4 md:p-6">
      <div className="mb-4 flex w-full flex-col md:flex-row">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">
              Non specialised security
            </FormLabel>
            <Select disabled value={data?.non_specialised_security ?? ""}>
              <SelectTrigger className="text-main h-12 w-full text-sm">
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(NON_SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="mt-11 flex w-4 items-start justify-center text-base font-normal md:w-8">
          or
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Specialised security</FormLabel>
            <Select disabled value={data?.specialised_security ?? ""}>
              <SelectTrigger className="text-main h-12 w-full text-sm">
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">
              Purchase price <span className="text-default italic">(As per contract)</span>
            </FormLabel>
            <NumericFormat
              disabled
              customInput={Input}
              thousandSeparator
              prefix="$"
              value={data?.purchase_price ?? ""}
            />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Estimated market value</FormLabel>
            <NumericFormat
              disabled
              customInput={Input}
              thousandSeparator
              prefix="$"
              value={data?.estimated_market_value ?? ""}
            />
          </FormItem>
        </div>
      </div>

      <div className="mb-8 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem className="flex items-start space-x-2 space-y-0">
            <FormControl>
              <Checkbox
                checked={data?.not_have_property_security ?? false}
                className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
              />
            </FormControl>
            <div className="flex flex-col">
              <FormLabel className="text-default text-sm font-normal">
                Don’t have property security?
              </FormLabel>
            </div>
          </FormItem>
        </div>

        <div className="hidden flex-1 md:block"></div>
      </div>

      <h3 className="text-main mb-4 text-lg font-semibold">Address</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Street address</FormLabel>
            <Input disabled autoComplete="off" value={data?.street_address ?? ""} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
            <Input disabled autoComplete="off" value={data?.suburb ?? ""} />
          </FormItem>
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">State</FormLabel>
            <Input disabled autoComplete="off" value={data?.state ?? ""} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
            <Input disabled autoComplete="off" value={data?.postcode ?? ""} />
          </FormItem>
        </div>
      </div>
    </div>
  )
}
