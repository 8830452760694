import { toast } from "sonner"
import { create } from "zustand"

import { apiSetInviteePassword, apiVerifyInvitation, apiVerifyInviteeMfa } from "@/api/authApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAcceptInvitationState {
  loading: boolean
  stage: number
  totalStage: number
  data: Record<string, any>
  qrCodeUri: string
  qrCodeKey: string

  verifyInvitationAction: (
    data: any,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => void
  setInviteePasswordAction: (data: any, onSuccessCallback?: () => void) => void
  verifyInviteeMfaAction: (
    token: string,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => void
}

const useAcceptInvitationStore = create<IAcceptInvitationState>()((set, get) => ({
  loading: false,
  stage: 1,
  totalStage: 2,
  data: {},
  qrCodeUri: "",
  qrCodeKey: "",

  verifyInvitationAction: async (data, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyInvitation(data)

      if (response?.data) {
        const { message, data } = response.data
        set({ stage: 1 })
        set({ data: data.user })
        set({ qrCodeUri: data.qrCodeUri })
        set({ qrCodeKey: data.qrCodeKey })

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  setInviteePasswordAction: async (data: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiSetInviteePassword(data)

      if (response?.data) {
        const { message } = response.data
        set({ stage: 2 })
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyInviteeMfaAction: async (token, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyInviteeMfa({
        token,
        user_uuid: get().data.uuid
      })

      if (response?.data) {
        const { message } = response.data
        set({ stage: 1 })
        set({ data: {} })
        set({ qrCodeUri: "" })
        set({ qrCodeKey: "" })
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useAcceptInvitationStore
