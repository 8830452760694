import React, { useState } from "react"

import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { Button } from "@repo/ui/components/ui/button"
import { RadioGroup, RadioGroupItem } from "@repo/ui/components/ui/radio-group"
import { Label } from "@repo/ui/components/ui/label"
import { CHECK_ITEM_TYPES } from "@repo/util/constant"

interface Props {
  fsIndex: number
  ltIndex: number
}

export default function CheckItems(props: Readonly<Props>) {
  const { fsIndex, ltIndex } = props
  const form = useFormContext()

  const [section, setSection] = useState("")
  const [item, setItem] = useState<{
    section: string
    type: string
    text: string
  }>({
    section: form.getValues()?.["fs"]?.[fsIndex]?.["lt"]?.[ltIndex]?.checkitems?.[0]?.section ?? "",
    type: CHECK_ITEM_TYPES.YesNo,
    text: ""
  })

  const handleAddSection = () => {
    form.setValue(`fs.${fsIndex}.lt.${ltIndex}.checkitems`, [
      ...(form.getValues()?.["fs"]?.[fsIndex]?.["lt"]?.[ltIndex]?.["checkitems"] ?? []),
      { section, items: [] }
    ])

    setSection("")
  }

  const handleRemoveSection = (redactedSection: string) => () => {
    form.setValue(
      `fs.${fsIndex}.lt.${ltIndex}.checkitems`,
      (form.getValues()?.["fs"]?.[fsIndex]?.["lt"]?.[ltIndex]?.checkitems ?? []).filter(
        (itm: any) => itm.section !== redactedSection
      )
    )
  }

  const handleAddItem = () => {
    const updatedValue = form.getValues()?.["fs"]?.[fsIndex]?.["lt"]?.[ltIndex]?.checkitems ?? []
    updatedValue
      .filter((itm: any) => itm.section === item.section)[0]
      .items?.push({ type: item.type, text: item.text })

    form.setValue(`fs.${fsIndex}.lt.${ltIndex}.checkitems`, updatedValue)

    setItem((prev) => ({
      ...prev,
      text: ""
    }))
  }

  const handleRemoveItem = (redactedSection: string, redactedText: string) => () => {
    let updatedValue = form.getValues()?.["fs"]?.[fsIndex]?.["lt"]?.[ltIndex]?.checkitems ?? []
    const sectionItem = updatedValue.find((itm: any) => itm.section === redactedSection)
    if (sectionItem) {
      sectionItem.items = sectionItem.items?.filter((subitem: any) => subitem.text !== redactedText)
    }
    form.setValue(`fs.${fsIndex}.lt.${ltIndex}.checkitems`, updatedValue)
  }

  return (
    <div className="mt-8">
      <h5 className="text-main mb-4 text-sm font-semibold">Check Items</h5>
      <h6 className="text-main mb-4 pl-4 text-sm font-semibold">Sections</h6>

      <div className="mb-4 ml-4 flex flex-col items-center gap-4 rounded-xl border p-4 lg:flex-row">
        <Input className="w-full" value={section} onChange={(e) => setSection(e.target.value)} />
        <Button className="w-full lg:w-auto" type="button" onClick={handleAddSection}>
          Add section
        </Button>
      </div>

      <div className="mb-4 ml-8 flex flex-col gap-2">
        {(form.watch(`fs.${fsIndex}.lt.${ltIndex}.checkitems`) ?? []).map((itm: any) => (
          <div key={itm.section} className="flex items-center justify-between">
            <div className="text-main text-sm">{itm.section}</div>
            <Button
              type="button"
              variant="destructive"
              size="sm"
              onClick={handleRemoveSection(itm.section)}
            >
              Remove
            </Button>
          </div>
        ))}
      </div>

      <h5 className="text-main mb-4 pl-4 text-sm font-semibold">Items</h5>

      <div className="mb-4 ml-4 flex flex-col gap-4 rounded-xl border p-4">
        <Select
          onValueChange={(value) => {
            setItem((prev) => ({ ...prev, section: value }))
          }}
          value={item.section}
        >
          <SelectTrigger
            className={clsx("h-12 w-full text-sm", item.section ? "text-main" : "text-default")}
          >
            <SelectValue placeholder="Please select" />
          </SelectTrigger>
          <SelectContent>
            {form.watch(`fs.${fsIndex}.lt.${ltIndex}.checkitems`)?.map((itm: any) => (
              <SelectItem key={itm.section} value={itm.section}>
                {itm.section}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <RadioGroup
          className="flex flex-col gap-4 lg:flex-row"
          defaultValue={CHECK_ITEM_TYPES.YesNo}
          value={item.type}
          onValueChange={(v) => setItem((prev) => ({ ...prev, type: v }))}
        >
          {Object.entries(CHECK_ITEM_TYPES).map(([k, v]) => (
            <div key={k} className="flex items-center space-x-2">
              <RadioGroupItem value={v} id={`${fsIndex}-${ltIndex}-checkitemtype-${v}`} />
              <Label htmlFor={`${fsIndex}-${ltIndex}-checkitemtype-${v}`}>{v}</Label>
            </div>
          ))}
        </RadioGroup>

        <Input
          className="w-full"
          value={item.text}
          onChange={(e) => setItem((prev) => ({ ...prev, text: e.target.value }))}
        />

        <Button className="w-full" type="button" onClick={handleAddItem}>
          Add item
        </Button>
      </div>

      <div className="mb-4 ml-8 flex flex-col gap-2">
        {(form.watch(`fs.${fsIndex}.lt.${ltIndex}.checkitems`) ?? []).map((itm: any) => (
          <div key={itm.section} className="flex flex-col gap-2">
            <div className="text-main text-sm">{itm.section}</div>
            <div className="ml-4 flex flex-col gap-2">
              {itm?.items?.map((subitem: any, index: number) => (
                <div key={`${subitem.text}-${index}`} className="flex items-center justify-between">
                  <div className="text-main text-xs">{subitem.text}</div>
                  <Button
                    type="button"
                    variant="destructive"
                    size="sm"
                    onClick={handleRemoveItem(itm.section, subitem.text)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
