import React from "react"

import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import { FileUpload } from "@/components/_uiext"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { BANK_BRANCHES } from "@repo/util/constant"

export default function BusinessBank() {
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h2 className="text-main py-4 text-xl font-semibold">Business Bank Information</h2>

      <h3 className="text-default mb-4 py-4 text-lg font-semibold">
        Where commission is to be paid
      </h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="account_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Name of account</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="bank_branch"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Bank branch</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {BANK_BRANCHES.map((bb) => (
                      <SelectItem key={bb} value={bb}>
                        {bb}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="bsb"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">BSB</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="account_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Account number</FormLabel>
                <Input autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="statement_proof"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Proof of bank statement
                </FormLabel>
                <FileUpload {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div
          className={clsx(
            "flex-1",
            form.watch("bank_branch") === "Other" ? "block" : "hidden md:block"
          )}
        >
          {form.watch("bank_branch") === "Other" && (
            <FormField
              control={form.control}
              name="other"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">Other</FormLabel>
                  <Input autoComplete="off" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </div>
      </div>
    </div>
  )
}
