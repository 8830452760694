import React from "react"

import { ColumnDef } from "@tanstack/react-table"
import { useNavigate } from "react-router-dom"

import { IconArchive, IconEdit, IconMore, IconUserCircle, IconView } from "@/components/_icons"
import useAdminUserStore from "@/stores/useAdminUserStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Button } from "@repo/ui/components/ui/button"
import { USER_ROLES, USER_STATUS } from "@repo/util/constant"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useAdminUserStore()

  const handleViewClick = (uuid: string) => () => {
    navigate(`/admin/users/view/${uuid}`)
  }

  const handleEditClick = (uuid: string) => () => {
    navigate(`/admin/users/edit/${uuid}`)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  if (row.original.role === USER_ROLES.Administrator) return null

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleViewClick(row.original.uuid)}
          >
            <IconView className="text-base" /> View
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleEditClick(row.original.uuid)}
          >
            <IconEdit className="text-base" />
            Edit
          </DropdownMenuItem>

          {(row.original.status === USER_STATUS.ACTIVE ||
            row.original.status === USER_STATUS.ARCHIVED) && (
            <DropdownMenuItem
              className="text-default flex gap-2 text-sm"
              onClick={handleArchiveClick(row.original.uuid)}
            >
              <IconArchive className="text-base" />
              {row.original.status === USER_STATUS.ACTIVE ? "Archive" : "Restore"}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "fullName",
    header: () => <>Full Name</>,
    cell: ({ row }) => (
      <div className="flex items-center justify-start gap-2.5">
        <Avatar>
          <AvatarImage
            className="h-10 w-10 rounded-full object-cover"
            src={row.original.image}
            alt={`${row.original.first_name} ${row.original.last_name}`}
          />
          <AvatarFallback className="uppercase">
            {row.original.first_name[0]}
            {row.original.last_name[0]}
          </AvatarFallback>
        </Avatar>

        <div className="text-sm">{`${row.original.first_name} ${row.original.last_name}`}</div>
      </div>
    )
  },
  {
    accessorKey: "entity_name",
    header: () => <>Entity Name</>,
    cell: ({ row }) => (
      <div className="text-sm">{row.original.bs?.registered_business_name ?? ""}</div>
    )
  },
  {
    accessorKey: "user_type",
    header: () => <>User Type</>,
    cell: ({ row }) => <div className="text-sm capitalize">{row.original.role ?? ""}</div>
  },
  {
    accessorKey: "email",
    header: () => <>Email Address</>,
    cell: ({ row }) => <div className="text-sm">{row.original.email}</div>
  },
  {
    accessorKey: "mobile_number",
    header: () => <>Mobile Number</>,
    cell: ({ row }) => <div className="text-sm">{row.original.mobile_number}</div>
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
