import React from "react"

import { useAdminAppContext } from "@/pages/admin/applications/AdminAppContext"

export default function BorrowerSummary() {
  const { data } = useAdminAppContext()

  return (
    <div className="relative flex w-full flex-col rounded-2xl border p-6">
      <h3 className="text-main mb-4 text-lg font-semibold">{data?.borrower?.entity_name}</h3>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <div className="flex flex-1 flex-col gap-2">
          <span className="text-default text-sm font-normal">Full name</span>
          <span className="text-main text-sm font-normal">
            {data?.borrower?.directors?.[0]?.full_name}
          </span>
        </div>

        <div className="flex flex-1 flex-col gap-2">
          <span className="text-default text-sm font-normal">Mobile number</span>
          <span className="text-main text-sm font-normal">
            {data?.borrower?.directors?.[0]?.mobile_number}
          </span>
        </div>

        <div className="flex flex-1 flex-col gap-2">
          <span className="text-default text-sm font-normal">Email address</span>
          <span className="text-main text-wrap break-words text-sm font-normal">
            {data?.borrower?.directors?.[0]?.email}
          </span>
        </div>
      </div>
    </div>
  )
}
