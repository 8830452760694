import React, { useEffect, useState, useCallback, useMemo } from "react"

import { useMapsLibrary } from "@vis.gl/react-google-maps"
import ClickAwayListener from "react-click-away-listener"

import { extractPlace } from "@/utils/google"

import { Input } from "@repo/ui/components/ui/input"

interface Props {
  textValue?: string
  onPlaceSelect: (place: Array<Record<string, any>> | null) => void
}

export default function PlacesAutoPredict({ textValue, onPlaceSelect }: Readonly<Props>) {
  const places = useMapsLibrary("places")
  const geocodingLib = useMapsLibrary("geocoding")

  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>()
  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null)
  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([])

  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>(textValue ?? "")

  const geocoder = useMemo(() => geocodingLib && new geocodingLib.Geocoder(), [geocodingLib])

  useEffect(() => {
    if (!places) return

    setAutocompleteService(new places.AutocompleteService())
    setSessionToken(new places.AutocompleteSessionToken())

    return () => setAutocompleteService(null)
  }, [places])

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([])
        return
      }

      const request = {
        input: inputValue,
        sessionToken,
        componentRestrictions: { country: ["Au", "Nz"] }
      }
      const response = await autocompleteService.getPlacePredictions(request)

      setPredictionResults(response.predictions)
    },
    [autocompleteService, sessionToken]
  )

  const onInputChange = useCallback(
    (value: string) => {
      setInputValue(value)
      fetchPredictions(value)
    },
    [fetchPredictions]
  )

  const handleSuggestionClick = useCallback(
    async (placeId: string) => {
      setOpen(false)

      if (!places) return

      const place = await geocoder?.geocode({ placeId })
      if (place && place?.results?.length > 0) {
        const { street } = extractPlace(place.results[0].address_components)
        setInputValue(street)
        onPlaceSelect(place.results[0].address_components)
      }
    },
    [onPlaceSelect, places, sessionToken]
  )

  useEffect(() => {
    setInputValue(textValue ?? "")
    fetchPredictions(textValue ?? "")
  }, [autocompleteService, textValue])

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className="relative w-full">
        <Input
          className="placeholder:text-default mb-1 h-12 w-full"
          value={inputValue}
          placeholder="Search address"
          onFocus={() => setOpen(true)}
          onChange={(e) => onInputChange(e.target.value)}
        />

        {open && (
          <ul className="absolute left-0 right-0 flex flex-col gap-2 rounded-xl border bg-white p-2">
            {predictionResults.length > 0 ? (
              predictionResults.map(({ place_id, description }) => {
                return (
                  <li
                    className="text-main hover:bg-accent p-2 text-sm"
                    key={place_id}
                    onClick={() => handleSuggestionClick(place_id)}
                  >
                    {description}
                  </li>
                )
              })
            ) : (
              <li className="text-main hover:bg-accent p-2 text-sm">No place found.</li>
            )}
          </ul>
        )}
      </div>
    </ClickAwayListener>
  )
}
