import { toast } from "sonner"
import { create } from "zustand"

import {
  apiCreateAtAdminApplicationDraft,
  apiFindLendersAtAdminApplicationDraft,
  apiGetBorrowersAtAdminApplicationDraft,
  apiSubmitAtAdminApplicationDraft,
  apiUpdateAtAdminApplicationDraft
} from "@/api/adminApplicationDraftsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminApplicationDraftState {
  locked: boolean

  getBorrowersAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void

  findLendersAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void

  createAction: (params: any, onSuccessCallback?: (data: any) => void) => void

  updateAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (data: any) => void
  ) => void

  submitAction: (applicationUuid: string, onSuccessCallback?: () => void) => void
}

const useAdminApplicationDraftStore = create<IAdminApplicationDraftState>()((set, get) => ({
  locked: false,

  getBorrowersAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiGetBorrowersAtAdminApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  findLendersAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiFindLendersAtAdminApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  createAction: async (params, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiCreateAtAdminApplicationDraft(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  updateAction: async (applicationUuid: string, params: any, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiUpdateAtAdminApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  },

  submitAction: async (applicationUuid, onSuccessCallback) => {
    try {
      set({ locked: true })

      const response = await apiSubmitAtAdminApplicationDraft(applicationUuid)

      if (response?.data) {
        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ locked: false })
    }
  }
}))

export default useAdminApplicationDraftStore
