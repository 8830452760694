import qs from "qs"

import api from "./api"

// Get
export const apiGetAdminLendersList = (data: any) =>
  api().get(`/admin/lenders?${qs.stringify(data)}`)
export const apiGetAdminLenderByUuid = (uuid: string) => api().get(`/admin/lenders/${uuid}`)

// Create
export const apiCreateAdminLender = (data: any) => api().postForm("/admin/lenders", data)

// Update
export const apiUpdateAdminLender = (uuid: string, data: any) =>
  api().putForm(`/admin/lenders/${uuid}`, data)

// Archive
export const apiArchiveAdminLender = (uuid: string) => api().patch(`/admin/lenders/${uuid}/archive`)

// Duplicate
export const apiDuplicateAdminLender = (uuid: string) =>
  api().put(`/admin/lenders/${uuid}/duplicate`)
