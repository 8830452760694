import React from "react"

import clsx from "clsx"
import { NumericFormat } from "react-number-format"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { FormItem, FormLabel } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import {
  ASSETS_TYPES,
  ENTITY_INDUSTRIES,
  ENTITY_TYPES,
  LIABILITIES_TYPES
} from "@repo/util/constant"

import { useAdminAppContext } from "@/pages/admin/applications/AdminAppContext"

export default function BorrowerDetailSummary() {
  const { data } = useAdminAppContext()

  return (
    <div className="flex w-full flex-col rounded-2xl border p-4 md:p-6">
      <Accordion type="multiple" className="w-full">
        <AccordionItem className="mb-4 rounded-2xl border p-6" value="guarantors">
          <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
            <div className="flex items-center justify-between">
              <h3 className="text-main text-lg font-semibold">Guarantor Details</h3>
            </div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col px-1 pt-6">
            {data?.guarantors.map((item: any) => (
              <div key={item.id} className="mb-4 flex flex-col rounded-xl border p-4">
                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Entity type</FormLabel>
                      <Select disabled value={item.entity_type}>
                        <SelectTrigger className="text-main h-12 w-full text-sm">
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                            <SelectItem key={k} value={v.key}>
                              {v.key}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Registered business name
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.registered_business_name} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Australian business number (ABN)
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.australian_business_number} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <div
                      className={clsx(
                        "",
                        item.entity_type === ENTITY_TYPES.CompanyPrivate.key ||
                          item.entity_type === ENTITY_TYPES.CompanyPublic.key ||
                          item.entity_type === ENTITY_TYPES.Trust.key
                          ? "block"
                          : "hidden"
                      )}
                    >
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Australian company number (ACN)
                        </FormLabel>
                        <Input disabled autoComplete="off" value={item.australian_company_number} />
                      </FormItem>
                    </div>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Website</FormLabel>
                      <Input disabled type="url" autoComplete="off" value={item.website} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Entity industry
                      </FormLabel>
                      <Select disabled value={item.entity_industry}>
                        <SelectTrigger className="text-main h-12 w-full text-sm">
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {ENTITY_INDUSTRIES.map((ei) => (
                            <SelectItem key={ei} value={ei}>
                              {ei}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Full name</FormLabel>
                      <Input disabled autoComplete="off" value={item.full_name} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Email address</FormLabel>
                      <Input disabled type="email" autoComplete="off" value={item.email} />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Mobile number</FormLabel>
                      <Input disabled autoComplete="off" type="tel" value={item.mobile_number} />
                    </FormItem>
                  </div>

                  <div className="hidden flex-1 md:block" />
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="multiple" className="w-full">
        <AccordionItem className="mb-4 rounded-2xl border p-6" value="assets">
          <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
            <div className="flex items-center justify-between">
              <h3 className="text-main  text-lg font-semibold">Assets</h3>
            </div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col px-1 pt-6">
            {data?.assets.map((item: any) => (
              <div key={item.id} className="mb-4 flex flex-col rounded-xl border p-4">
                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        What assets does the borrower own?
                      </FormLabel>
                      <Select disabled value={item.assets_type}>
                        <SelectTrigger className="text-main h-12 w-full text-sm">
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(ASSETS_TYPES).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Value</FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        value={item.assets_value ?? ""}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Who is the owner of the asset?
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.assets_owner} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Ownership percentage
                      </FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        prefix="%"
                        decimalScale={2}
                        fixedDecimalScale
                        value={item.assets_ownership_percentage ?? ""}
                      />
                    </FormItem>
                  </div>
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      <Accordion type="multiple" className="w-full">
        <AccordionItem className="mb-4 rounded-2xl border p-6" value="assets">
          <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
            <div className="flex items-center justify-between">
              <h3 className="text-main text-lg font-semibold">Liabilities</h3>
            </div>
          </AccordionTrigger>
          <AccordionContent className="flex flex-col px-1 pt-6">
            {data?.liabilities.map((item: any) => (
              <div key={item.id} className="mb-4 flex flex-col rounded-xl border p-4">
                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        What liabilities does the borrower have?{" "}
                        <span className="text-default italic">required</span>
                      </FormLabel>
                      <Select disabled value={item.liabilities_type}>
                        <SelectTrigger className="text-main h-12 w-full text-sm">
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(LIABILITIES_TYPES).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Balance / limit owing
                      </FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        prefix="$"
                        value={item.liabilities_balance}
                      />
                    </FormItem>
                  </div>
                </div>

                <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Who is the lender?
                      </FormLabel>
                      <Input disabled autoComplete="off" value={item.liabilities_lender} />
                    </FormItem>
                  </div>

                  <div className="flex-1">
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        What is your borrowers percentage of debt?
                      </FormLabel>
                      <NumericFormat
                        disabled
                        customInput={Input}
                        thousandSeparator
                        suffix="%"
                        decimalScale={2}
                        fixedDecimalScale
                        value={item.liabilities_debt_percentage}
                      />
                    </FormItem>
                  </div>
                </div>
              </div>
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
