import React from "react"

import { useFormContext } from "react-hook-form"

interface Props {
  fsIndex: number
  ltIndex: number
}

export default function CheckItems(props: Readonly<Props>) {
  const { fsIndex, ltIndex } = props
  const form = useFormContext()

  return (
    <div className="mt-8">
      <h5 className="text-main mb-4 text-sm font-semibold">Check Items</h5>
      <h6 className="text-main mb-4 pl-4 text-sm font-semibold">Sections</h6>

      <div className="mb-4 ml-8 flex flex-col gap-2">
        {(form.watch(`fs.${fsIndex}.lt.${ltIndex}.checkitems`) ?? []).map((itm: any) => (
          <div key={itm.section} className="flex items-center justify-between">
            <div className="text-main text-sm">{itm.section}</div>
          </div>
        ))}
      </div>

      <h5 className="text-main mb-4 pl-4 text-sm font-semibold">Items</h5>

      <div className="mb-4 ml-8 flex flex-col gap-2">
        {(form.watch(`fs.${fsIndex}.lt.${ltIndex}.checkitems`) ?? []).map((itm: any) => (
          <div key={itm.section} className="flex flex-col gap-2">
            <div className="text-main text-sm">{itm.section}</div>
            <div className="ml-4 flex flex-col gap-2">
              {itm?.items?.map((subitem: any, index: number) => (
                <div key={`${subitem.text}-${index}`} className="flex items-center justify-between">
                  <div className="text-main text-xs">{subitem.text}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
