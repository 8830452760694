import React, { useEffect, useState } from "react"

import { Text, View } from "@react-pdf/renderer"
import numeral from "numeral"

interface Props {
  data: Record<string, any>
}

export default function ContentLenderSelection(props: Readonly<Props>) {
  const { data } = props

  const [filteredFslt, setFilteredFslt] = useState<any>(null)

  useEffect(() => {
    const tempFilteredFslt = data?.lender?.fs
      ?.filter((fs: any) => (fs.funding_solution = data.funding_solution))?.[0]
      ?.lt?.filter((lt: any) => lt.loan_type === data.loan_type)?.[0]

    setFilteredFslt(tempFilteredFslt)
  }, [data])

  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 18
        }}
      >
        Lender Selection
      </Text>

      <View
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: "1px solid #CDCDCD",
          borderRadius: 8
        }}
      >
        <Text style={{ color: "#0E052B", fontWeight: 600, fontSize: 16 }}>
          {data?.lender?.lender_number}
        </Text>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 16
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16
            }}
          >
            <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                Interest Rate From
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                {filteredFslt?.interest_rate ?? 0}%
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Maximum LVR</Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                {filteredFslt?.loan_value_ratio ?? 0}%
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16
            }}
          >
            <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                Min. Loan Size
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                {numeral(filteredFslt?.minimum_loan_size).format("$ 0[.]00 a")}
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                Max. Loan Size
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                {numeral(filteredFslt?.maximum_loan_size).format("$ 0[.]00 a")}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16
            }}
          >
            <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                Min. Loan Term
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                {filteredFslt?.minimum_loan_term ?? 0} Months
              </Text>
            </View>

            <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
                Max. Loan Term
              </Text>
              <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                {filteredFslt?.maximum_loan_term ?? 0} Months
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16
          }}
        >
          <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Payment Options</Text>
            <View style={{ display: "flex", flexDirection: "column", gap: 4 }}>
              {filteredFslt?.repayments?.map((r: string) => (
                <Text key={r} style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
                  {r}
                </Text>
              ))}
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
