import React from "react"

import { IconApplicantType } from "@/components/_icons"
import { useAppContext } from "@/pages/applications/AppContext"

export default function ApplicantTypeSummary() {
  const { data } = useAppContext()

  return (
    <div className="flex w-full items-center gap-4 rounded-2xl border p-4 md:p-6">
      <div className="bg-primary flex max-h-12 min-h-12 min-w-12 max-w-12 items-center justify-center rounded-full">
        <IconApplicantType className="text-2xl text-white" />
      </div>

      <div className="text-main text-base font-semibold">{data?.applicant_type}</div>
    </div>
  )
}
