import React from "react"

import { FormItem, FormLabel } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { NON_SPECIALISED_DEVELOPMENTS, SPECIALISED_DEVELOPMENTS } from "@repo/util/constant"

import { useAppContext } from "@/pages/applications/AppContext"

export default function ConstructionFinance() {
  const { data } = useAppContext()

  return (
    <div className="flex w-full flex-col gap-4 rounded-2xl border p-4 md:p-6">
      <div className="mb-4 flex w-full flex-col md:flex-row">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">
              Non specialised security
            </FormLabel>
            <Select disabled value={data?.non_specialised_security ?? ""}>
              <SelectTrigger className="text-main h-12 w-full text-sm">
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(NON_SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>

        <div className="mt-11 flex w-4 items-start justify-center text-base font-normal md:w-8">
          or
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Specialised security</FormLabel>
            <Select disabled value={data?.specialised_security ?? ""}>
              <SelectTrigger className="text-main h-12 w-full text-sm">
                <SelectValue placeholder="Please select" />
              </SelectTrigger>
              <SelectContent>
                {Object.entries(SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                  <SelectItem key={k} value={v}>
                    {v}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        </div>
      </div>

      <h3 className="text-main mb-4 text-lg font-semibold">Address</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Street address</FormLabel>
            <Input disabled autoComplete="off" value={data?.street_address ?? ""} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
            <Input disabled autoComplete="off" value={data?.suburb ?? ""} />
          </FormItem>
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">State</FormLabel>
            <Input disabled autoComplete="off" value={data?.state ?? ""} />
          </FormItem>
        </div>

        <div className="flex-1">
          <FormItem>
            <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
            <Input disabled autoComplete="off" value={data?.postcode ?? ""} />
          </FormItem>
        </div>
      </div>
    </div>
  )
}
