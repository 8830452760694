import { toast } from "sonner"
import { create } from "zustand"

import {
  apiCreateAtApplicationDraft,
  apiFindLendersAtApplicationDraft,
  apiGetBorrowersAtApplicationDraft,
  apiSubmitAtApplicationDraft,
  apiUpdateAtApplicationDraft
} from "@/api/applicationDraftsApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IApplicationDraftState {
  loading: boolean

  getBorrowersAction: (
    applicationUuid: string,
    params: any,
    onSuccessCallback?: (newData: any) => void
  ) => void
  findLendersAction: (uuid: string, params: any, onSuccessCallback?: (data: any) => void) => void

  createAction: (params: any, onSuccessCallback?: (data: any) => void) => void
  updateAction: (uuid: string, params: any, onSuccessCallback?: (data: any) => void) => void
  submitAction: (uuid: string, onSuccessCallback?: () => void) => void
}

const useApplicationDraftStore = create<IApplicationDraftState>()((set, get) => ({
  loading: false,

  getBorrowersAction: async (applicationUuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetBorrowersAtApplicationDraft(applicationUuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  findLendersAction: async (uuid, params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiFindLendersAtApplicationDraft(uuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  createAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiCreateAtApplicationDraft(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateAction: async (uuid: string, params: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAtApplicationDraft(uuid, params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  submitAction: async (uuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiSubmitAtApplicationDraft(uuid)

      if (response?.data) {
        const { message } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  }
}))

export default useApplicationDraftStore
