import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { StickyShortcut } from "@/components/_uiext"
import useAdminUserStore from "@/stores/useAdminUserStore"

import { Button } from "@repo/ui/components/ui/button"

import BasicInformation from "./sections/BasicInformation"
import BusinessUser from "./sections/BusinessUser"

const FormSchema = z.object({
  image: z.any().refine((file) => file !== null, "Image is required"),
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().email({
    message: "Please enter a valid email address"
  }),
  mobile_number: z.string().min(1, {
    message: "Please enter a valid mobile number"
  }),
  role: z.string().min(1, { message: "User type is required" }),
  membership_association: z.string().min(1, { message: "Association is required" }),
  membership_certificate: z
    .any()
    .refine((file) => file !== null, "Membership Certificate is required"),
  membership_expiry: z.coerce.date(),
  insurance_certificate: z
    .any()
    .refine((file) => file !== null, "Certificate of Currency is required"),
  insurance_expiry: z.coerce.date()
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: any
}

export default function EmployeeForm({ data }: Readonly<Props>) {
  const store = useAdminUserStore()
  const navigate = useNavigate()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 1 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      role: "",
      membership_association: "",
      membership_certificate: null,
      insurance_certificate: null
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/users/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(data.uuid, values, () => {
      navigate("/admin/users/list")
    })
  }

  useEffect(() => {
    form.reset({
      image: data?.image,
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      mobile_number: data?.mobile_number,
      role: data?.role,
      membership_association: data?.bu?.membership_association,
      membership_certificate: data?.bu?.membership_certificate,
      membership_expiry: data?.bu?.membership_expiry,
      insurance_certificate: data?.bu?.insurance_certificate,
      insurance_expiry: data?.bu?.insurance_expiry
    })
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <div ref={ref1} id="basic-information">
              <BasicInformation />
            </div>

            <div ref={ref2} id="business-user">
              <BusinessUser />
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "basic-information",
                label: "Basic information",
                inView: inView1
              },
              {
                id: "business-user",
                label: "Business User Information",
                inView: inView2
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
