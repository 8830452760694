import React from "react"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"
import { useAppContext } from "@/pages/applications/AppContext"

import Default from "./Default"
import ConstructionFinance from "./ConstructionFinance"

export default function SecurityDetailsSummary() {
  const { data } = useAppContext()

  if (data?.funding_solution === FUNDING_SOLUTIONS.ConstructionFinance.key)
    return <ConstructionFinance />

  return <Default />
}
