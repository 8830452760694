import React from "react"

import { format } from "date-fns"
import { useFormContext } from "react-hook-form"
import { PatternFormat } from "react-number-format"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Textarea } from "@repo/ui/components/ui/textarea"

export default function LeadDetails() {
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Lead Details</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="full_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main mb-2 text-sm font-normal">Full Name</FormLabel>
                <Input disabled autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="mobile_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main mb-2 text-sm font-normal">Mobile Number</FormLabel>
                <PatternFormat
                  disabled
                  customInput={Input}
                  format="04## ### ###"
                  mask="_"
                  allowEmptyFormatting={true}
                  getInputRef={field.ref}
                  name={field.name}
                  value={field.value.slice(2) ?? ""}
                  onBlur={field.onBlur}
                  onValueChange={(values, sourceInfo) => {
                    field.onChange(values.formattedValue)
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main mb-2 text-sm font-normal">Email Address</FormLabel>
                <Input disabled autoComplete="off" type="email" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="created_at"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main mb-2 text-sm font-normal">
                  Submission Time
                </FormLabel>
                <Input
                  disabled
                  autoComplete="off"
                  {...field}
                  value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="requirement"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main mb-2 text-sm font-normal">
                  Tell us about your leads requirement
                </FormLabel>
                <Textarea disabled {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
