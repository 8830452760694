import React from "react"

import { Text, View } from "@react-pdf/renderer"
import numeral from "numeral"
import { format } from "date-fns"

interface Props {
  data: Record<string, any>
}

export default function ContentLoanDetails(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 18
        }}
      >
        Loan Details
      </Text>

      <View
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: "1px solid #CDCDCD",
          borderRadius: 8
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Loan amount</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {numeral(data?.loan_amount).format("$ 0,0[.]00")}
            </Text>
          </View>

          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
              Project End Value
            </Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {numeral(data?.project_end_value).format("$ 0,0[.]00")}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Loan term</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.loan_term} months
            </Text>
          </View>

          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
              When do you require the loan by?
            </Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {format(data?.loan_by, "dd/MM/yyyy")}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
              What is your exit strategy?
            </Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.exit_strategy}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
