import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { APIProvider } from "@vis.gl/react-google-maps"
import clsx from "clsx"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconNext } from "@/components/_icons"
import { LoadingButton, PlacesAutoPredict } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"
import { extractPlace } from "@/utils/google"

import { Button } from "@repo/ui/components/ui/button"
import { Form, FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { NON_SPECIALISED_DEVELOPMENTS, SPECIALISED_DEVELOPMENTS } from "@repo/util/constant"

import { LOADING_TIMEOUT } from "../../../util"
import { useAdminAppContext } from "../../../AdminAppContext"

const FormSchema = z.object({
  non_specialised_security: z.string(),
  specialised_security: z.string(),

  street_address: z.string().min(1, { message: "Street address is required" }),
  suburb: z.string().min(1, { message: "Suburb is required" }),
  state: z.string().min(1, { message: "State is required" }),
  postcode: z.string().min(1, { message: "Postcode is required" })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function SecurityDetailsConstructionFinance() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAdminAppContext()
  const navigate = useNavigate()
  const store = useAdminApplicationDraftStore()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      non_specialised_security: "",
      specialised_security: "",

      street_address: "",
      suburb: "",
      state: "",
      postcode: ""
    }
  })

  const handlePlaceSelect = (address_components: Array<Record<string, any>> | null) => {
    if (address_components) {
      const placeInfo = extractPlace(address_components)

      form.setValue("street_address", placeInfo.street)
      form.setValue("suburb", placeInfo.suburb)
      form.setValue("state", placeInfo.state?.long_name)
      form.setValue("postcode", placeInfo.postcode)
    }
  }

  const handleBackClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep - 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev - 1)

        setTimeout(() => {
          setLoading(false)
        }, LOADING_TIMEOUT)
      }
    )
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/admin/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      non_specialised_security: data?.non_specialised_security,
      specialised_security: data?.specialised_security,
      street_address: data?.street_address ?? "",
      suburb: data?.suburb ?? "",
      state: data?.state ?? "",
      postcode: data?.postcode ?? ""
    })
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-[352px]">
            <h3 className="text-main mb-2 text-lg font-semibold">Security Details</h3>

            <p className="text-default mb-4 text-sm font-normal">Please enter security details.</p>

            <div className="mb-4 flex w-full flex-col md:flex-row">
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="non_specialised_security"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Non specialised security
                      </FormLabel>
                      <Select
                        onValueChange={(v) => {
                          field.onChange(v)
                          form.setValue("specialised_security", "")
                        }}
                        value={field.value}
                      >
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            field.value ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(NON_SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mt-11 flex w-4 items-start justify-center text-base font-normal md:w-8">
                or
              </div>

              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="specialised_security"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Specialised security
                      </FormLabel>
                      <Select
                        onValueChange={(v) => {
                          field.onChange(v)
                          form.setValue("non_specialised_security", "")
                        }}
                        value={field.value}
                      >
                        <SelectTrigger
                          className={clsx(
                            "h-12 w-full text-sm",
                            field.value ? "text-main" : "text-default"
                          )}
                        >
                          <SelectValue placeholder="Please select" />
                        </SelectTrigger>
                        <SelectContent>
                          {Object.entries(SPECIALISED_DEVELOPMENTS).map(([k, v]) => (
                            <SelectItem key={k} value={v}>
                              {v}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <h3 className="text-main mb-4 text-lg font-semibold">Address</h3>

            <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="street_address"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">
                        Street address
                      </FormLabel>
                      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_API_KEY ?? ""}>
                        <PlacesAutoPredict
                          textValue={field.value}
                          onPlaceSelect={handlePlaceSelect}
                        />
                      </APIProvider>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="suburb"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                      <Input autoComplete="off" {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                      <Input autoComplete="off" {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex-1">
                <FormField
                  control={form.control}
                  name="postcode"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                      <Input autoComplete="off" {...field} />
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full justify-between gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.locked}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
