import React, { useState, useRef, useCallback, type KeyboardEvent, useEffect } from "react"

import clsx from "clsx"
import { Command as CommandPrimitive } from "cmdk"
import { LuCheck } from "react-icons/lu"
import { Oval } from "react-loader-spinner"
import { useDebounce } from "use-debounce"

import ApiAusAbr from "@/api/ausAbrApi"

import {
  CommandGroup,
  CommandItem,
  CommandList,
  CommandInput,
  CommandEmpty
} from "@repo/ui/components/ui/command"

interface Props {
  disabled?: boolean
  placeholder?: string
  emptyPlaceholder?: string
  value?: string
  nameValue?: string
  onValueChange?: (value: Record<string, any>) => void
}

export default function EntityAutoComplete(props: Readonly<Props>) {
  const {
    disabled,
    placeholder = "Search business name",
    emptyPlaceholder = "No entity found.",
    value = null,
    nameValue = "",
    onValueChange
  } = props

  const inputRef = useRef<HTMLInputElement>(null)

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [items, setItems] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<Record<string, any> | null>(null)

  const [shouldSearch, setShouldSearch] = useState(false)
  const [inputValue, setInputValue] = useState<string>("")
  const [debouncedInputValue] = useDebounce(inputValue, 300)

  const initialize = async () => {
    setLoading(true)

    setInputValue(nameValue ?? "")
    const newItems = await ApiAusAbr.searchByName({ name: nameValue ?? "" })

    if (value) {
      const newSelectedItem = newItems?.filter((d: any) => d.Abn === value)?.[0]
      setSelectedItem(newSelectedItem)
      // setInputValue(newSelectedItem?.Name)
    }

    setItems(newItems)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const search = async () => {
    setLoading(true)
    const newItems = await ApiAusAbr.searchByName({ name: debouncedInputValue ?? "" })

    setItems(newItems)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      // Keep the options displayed when the user is typing
      if (!open) {
        setOpen(true)
      }

      // This is not a default behavior of the <input /> field
      if (event.key === "Enter" && input.value !== "") {
        const optionToSelect = items.find((option) => option.Name === input.value)
        if (optionToSelect) {
          setSelectedItem(optionToSelect)
          onValueChange?.(optionToSelect)
        }
      }

      if (event.key === "Escape") {
        input.blur()
      }
    },
    [open, items, onValueChange]
  )

  const handleBlur = useCallback(() => {
    setOpen(false)
    setInputValue(selectedItem?.Name)
  }, [selectedItem])

  const handleSelectOption = useCallback(
    (opt: Record<string, any>) => {
      setShouldSearch(false)
      setInputValue(opt.Name)
      setSelectedItem(opt)
      onValueChange?.(opt)

      // This is a hack to prevent the input from being focused after the user selects an option
      setTimeout(() => {
        inputRef?.current?.blur()
      }, 0)
    },
    [onValueChange]
  )

  useEffect(() => {
    initialize()
  }, [value])

  useEffect(() => {
    if (debouncedInputValue && shouldSearch) {
      search()
    }
  }, [debouncedInputValue])

  return (
    <CommandPrimitive onKeyDown={handleKeyDown} shouldFilter={false}>
      <CommandInput
        ref={inputRef}
        value={inputValue}
        onValueChange={(v) => {
          setInputValue(v)
          setShouldSearch(true)
        }}
        onBlur={handleBlur}
        onFocus={() => setOpen(true)}
        placeholder={placeholder}
        disabled={disabled}
        className="text-main placeholder:text-default h-12 text-sm"
      />

      <div className="relative mt-1">
        <div
          className={clsx(
            "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none",
            open ? "block" : "hidden"
          )}
        >
          <CommandList className="rounded-lg ring-1 ring-slate-200">
            {loading && (
              <div className="flex justify-center p-4">
                <Oval
                  visible={true}
                  height="40"
                  width="40"
                  color="#0E052B"
                  secondaryColor="#868194"
                />
              </div>
            )}

            {!loading && (
              <>
                <CommandEmpty>{emptyPlaceholder}</CommandEmpty>
                <CommandGroup>
                  {items.map((opt) => (
                    <CommandItem
                      key={opt.Abn}
                      value={opt.Abn}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => handleSelectOption(opt)}
                      className="flex items-center justify-between gap-2.5"
                    >
                      <div
                        className={clsx(
                          "flex flex-col",
                          selectedItem?.Abn === opt.Abn
                            ? "text-base font-semibold"
                            : "text-sm font-normal"
                        )}
                      >
                        <div>{opt.Name}</div>
                        <div>
                          {opt.Abn} - {opt.State}, {opt.Postcode}
                        </div>
                      </div>

                      {selectedItem?.Abn === opt.Abn && <LuCheck className="text-main text-base" />}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </>
            )}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  )
}
