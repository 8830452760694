import qs from "qs"

import api from "./api"

// Get
export const apiGetBorrowersList = (data: any) => api().get(`/borrowers?${qs.stringify(data)}`)
export const apiGetBorrowerByUuid = (uuid: string) => api().get(`/borrowers/${uuid}`)

// Create
export const apiCreateBorrower = (data: any) => api().postForm("/borrowers", data)

// Update
export const apiUpdateBorrower = (uuid: string, data: any) =>
  api().putForm(`/borrowers/${uuid}`, data)

// Archive
export const apiArchiveBorrower = (uuid: string) => api().patch(`/borrowers/${uuid}/archive`)
