import React from "react"

import { useFieldArray, useFormContext } from "react-hook-form"

import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { LOAN_TYPES } from "@repo/util/constant"

import LoanTypes from "./LoanTypes"

export default function FundingSolutions() {
  const form = useFormContext()

  const { fields } = useFieldArray({
    control: form.control,
    name: "fs"
  })

  return (
    <div className="flex flex-col gap-4">
      {fields.map((item: any, index) => (
        <div key={item.id} className="border-primary rounded-xl border p-4">
          <h4 className="text-main mb-4 text-base font-semibold">
            Funding Solution: <span className="text-primary">{item.funding_solution}</span>
          </h4>

          <div className="mb-8 w-full">
            <FormField
              control={form.control}
              name={`fs.${index}.loan_types`}
              render={({ field }) => (
                <div className="flex flex-col gap-2">
                  <FormLabel className="text-main text-base font-medium">Loan Types</FormLabel>
                  <div className="grid grid-cols-1 gap-2 md:grid-cols-2 2xl:grid-cols-3">
                    {Object.values(LOAN_TYPES)
                      .filter((lt) => lt.funding_solutions.includes(item.funding_solution))
                      .map((lt) => (
                        <FormItem key={lt.key} className="flex items-center space-x-2 space-y-0">
                          <FormControl>
                            <Checkbox
                              checked={field.value?.includes(lt.key)}
                              onCheckedChange={(checked) => {
                                const updatedValue = field.value || []
                                if (checked) {
                                  field.onChange([...updatedValue, lt.key])
                                  form.setValue(`fs.${index}.lt`, [
                                    ...form.getValues()["fs"][index]["lt"],
                                    {
                                      loan_type: lt.key
                                    }
                                  ])
                                } else {
                                  field.onChange(
                                    updatedValue.filter((item: string) => item !== lt.key)
                                  )
                                  form.setValue(
                                    `fs.${index}.lt`,
                                    form
                                      .getValues()
                                      [
                                        "fs"
                                      ][index]["lt"].filter((item: any) => item.loan_type !== lt.key)
                                  )
                                }
                              }}
                              className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                            />
                          </FormControl>
                          <FormLabel className="text-main font-normal">{lt.key}</FormLabel>
                        </FormItem>
                      ))}
                  </div>
                  <FormMessage />
                </div>
              )}
            />
          </div>

          <LoanTypes fsIndex={index} />
        </div>
      ))}
    </div>
  )
}
