import React from "react"

import { Text, View, Svg, Path } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export default function ContentLoanDocumentType(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 18
        }}
      >
        Loan Document Type
      </Text>

      <View
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 16,
          border: "1px solid #CDCDCD",
          borderRadius: 8
        }}
      >
        <View
          style={{
            backgroundColor: "#08ECB3",
            height: 48,
            width: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%"
          }}
        >
          <Svg width="16" height="16" viewBox="0 0 18 18">
            <Path
              fillRule="evenodd"
              d="M1.51172 11.7148C1.51172 11.7148 1.63005 13.1692 1.65755 13.6277C1.69422 14.2427 1.93089 14.9297 2.32589 15.4067C2.88339 16.0828 3.54005 16.3213 4.41672 16.323C5.44755 16.3246 12.9434 16.3246 13.9742 16.323C14.8509 16.3213 15.5076 16.0828 16.0651 15.4067C16.4601 14.9297 16.6967 14.2427 16.7342 13.6277C16.7609 13.1692 16.8792 11.7148 16.8792 11.7148"
              fill="none"
            />

            <Path
              d="M1.51172 11.7148C1.51172 11.7148 1.63005 13.1692 1.65755 13.6277C1.69422 14.2427 1.93089 14.9297 2.32589 15.4067C2.88339 16.0828 3.54005 16.3213 4.41672 16.323C5.44755 16.3246 12.9434 16.3246 13.9742 16.323C14.8509 16.3213 15.5076 16.0828 16.0651 15.4067C16.4601 14.9297 16.6967 14.2427 16.7342 13.6277C16.7609 13.1692 16.8792 11.7148 16.8792 11.7148"
              stroke="white"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />

            <Path
              fillRule="evenodd"
              d="M6.25586 3.22347V2.91302C6.25586 1.89216 7.07919 1.06543 8.09586 1.06543H10.2475C11.2634 1.06543 12.0875 1.89216 12.0875 2.91302L12.0884 3.22347"
              fill="none"
            />

            <Path
              d="M6.25586 3.22347V2.91302C6.25586 1.89216 7.07919 1.06543 8.09586 1.06543H10.2475C11.2634 1.06543 12.0875 1.89216 12.0875 2.91302L12.0884 3.22347"
              stroke="white"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />

            <Path fillRule="evenodd" d="M9.17155 12.7185V11.6357V12.7185Z" fill="white" />

            <Path
              d="M9.17155 12.7185V11.6357"
              stroke="white"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />

            <Path
              fillRule="evenodd"
              d="M1.4668 5.78346V8.68455C3.06513 9.74307 4.98013 10.4844 7.0818 10.7782C7.33346 9.85687 8.16096 9.18159 9.1668 9.18159C10.1568 9.18159 11.001 9.85687 11.236 10.7865C13.346 10.4928 15.2685 9.75144 16.8751 8.68455V5.78346C16.8751 4.36597 15.7393 3.22461 14.3276 3.22461H4.02263C2.61096 3.22461 1.4668 4.36597 1.4668 5.78346Z"
              fill="none"
              stroke="white"
              strokeWidth="1.5"
              strokeLinejoin="round"
            />
          </Svg>
        </View>

        <Text style={{ color: "#0E052B", fontWeight: 600, fontSize: 16 }}>
          {data?.loan_document_type}
        </Text>
      </View>
    </View>
  )
}
