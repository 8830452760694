import React from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useAdminLenderStore from "@/stores/useAdminLenderStore"

import { Button } from "@repo/ui/components/ui/button"

import BasicInformation from "./sections/BasicInformation"
import LenderDetails from "./sections/LenderDetails"
import FilesAndAttachments from "./sections/FilesAndAttachments"

const FormSchema = z.object({
  lender_name: z.string().min(1, { message: "Lender name is required" }),
  lender_number: z.string().min(1, { message: "Lender number is required" }),
  bdm_full_name: z.string().min(1, { message: "BDM full name is required" }),
  bdm_email: z.string().email({
    message: "BDM email address is required"
  }),
  bdm_mobile_number: z.string().min(1, {
    message: "BDM mobile number is required"
  }),
  website: z
    .string()
    .url({ message: "Please enter a valid website url" })
    .optional()
    .or(z.literal("")),

  funding_solutions: z
    .array(z.string())
    .min(1, { message: "Lender should contain at least 1 funding solution" }),

  fs: z.array(
    z.object({
      funding_solution: z.string().min(1, { message: "Funding solution is required" }),

      loan_types: z
        .array(z.string())
        .min(1, { message: "Funding solution should contain at least 1 loan type" }),

      lt: z.array(
        z.object({
          loan_type: z.string().min(1, { message: "Loan type is required" }),

          applicant_types: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 applicant type"
            })
            .min(1, { message: "Loan type should contain at least 1 applicant type" }),
          loan_document_types: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 loan document type"
            })
            .min(1, { message: "Loan type should contain at least 1 loan document type" }),

          minimum_loan_size: z.coerce.number().positive(),
          maximum_loan_size: z.coerce.number().positive(),
          is_higher_loan_amount: z.boolean().optional().default(false),

          minimum_loan_term: z.string({ required_error: "Minimum loan term is required" }),
          maximum_loan_term: z.string({ required_error: "Maximum loan term is required" }),
          loan_value_ratio: z.coerce.number(),

          exit_strategies: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 exit strategy"
            })
            .min(1, { message: "Loan type should contain at least 1 exit strategy" }),

          nsd: z.array(z.string()).optional(),
          sd: z.array(z.string()).optional(),

          countries: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 country"
            })
            .min(1, { message: "Loan type should contain at least 1 country" }),

          states: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 state"
            })
            .min(1, { message: "Loan type should contain at least 1 state" }),

          regions: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 region"
            })
            .min(1, { message: "Loan type should contain at least 1 region" }),

          repayments: z
            .array(z.string(), {
              required_error: "Loan type should contain at least 1 repayment option"
            })
            .min(1, { message: "Loan type should contain at least 1 repayment option" }),

          establishment_fee: z.coerce.number(),
          interest_rate: z.coerce.number(),
          mortgage_management_fee: z.boolean().optional().default(false),
          line_fee: z.boolean().optional().default(false),
          pre_sales_requirement: z.boolean().optional().default(false),

          checkitems: z
            .array(
              z.object({
                section: z.string().min(1, { message: "Section is required" }),
                items: z
                  .array(
                    z.object({
                      type: z.string().min(1, { message: "Type is required" }),
                      text: z.string().min(1, { message: "Text is required" })
                    })
                  )
                  .default([])
              })
            )
            .default([])
        })
      )
    })
  ),

  documents: z.array(z.instanceof(File)).optional()
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function MainForm() {
  const store = useAdminLenderStore()
  const navigate = useNavigate()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.1 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 1 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      lender_name: "",
      lender_number: "",
      bdm_full_name: "",
      bdm_email: "",
      bdm_mobile_number: "",
      website: undefined,

      funding_solutions: [],
      fs: [],
      documents: []
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/lenders/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.createAction(values, () => {
      navigate("/admin/lenders/list")
    })
  }

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <div ref={ref1} id="basic-information">
              <BasicInformation />
            </div>

            <div ref={ref2} id="lender-details">
              <LenderDetails />
            </div>

            <div ref={ref3} id="files-and-attachments">
              <FilesAndAttachments />
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "basic-information",
                label: "Basic information",
                inView: inView1
              },
              {
                id: "lender-details",
                label: "Lender Details",
                inView: inView2
              },
              {
                id: "files-and-attachments",
                label: "Files and Attachments",
                inView: inView3
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton loading={store.loading} type="submit" className="px-6 py-4 md:!w-auto">
            Add lender
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}
