import React from "react"

import { useFormContext } from "react-hook-form"

import { LenderFileUpload } from "@/components/_uiext"

import { FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"

export default function FilesAndAttachments() {
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <div className="w-full">
        <FormField
          control={form.control}
          name="documents"
          render={({ field }) => (
            <FormItem>
              <LenderFileUpload title="Files and Attachments" {...field} />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}
