import qs from "qs"

import api from "./api"

// Get
export const apiGetAdminUserList = (data: any) => api().get(`/admin/users?${qs.stringify(data)}`)
export const apiGetAdminUserByUuid = (uuid: string) => api().get(`/admin/users/${uuid}`)

// Update
export const apiUpdateAdminUser = (uuid: string, data: any) =>
  api().putForm(`/admin/users/${uuid}`, data)

// Archive
export const apiArchiveAdminUser = (uuid: string) => api().patch(`/admin/users/${uuid}/archive`)
