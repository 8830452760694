import React, { useEffect, useState } from "react"

import clsx from "clsx"
import { format } from "date-fns"
import { Link, useNavigate } from "react-router-dom"
import { LuBoxes, LuChevronDown, LuChevronUp } from "react-icons/lu"

import {
  IconDrawerOpen,
  IconMyApplications,
  IconUserCircle,
  IconDrawerClose,
  IconNotification,
  IconAccountSettings,
  IconLogOut,
  IconLender,
  IconHome,
  IconMyBorrowers
} from "@/components/_icons"
import useAuthStore from "@/stores/useAuthStore"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"
import { Button } from "@repo/ui/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "@repo/ui/components/ui/sheet"
import { NOTIFICATION_TYPES, USER_ROLES } from "@repo/util/constant"

import Logo from "../components/Logo"

export default function AdminDrawer() {
  const authStore = useAuthStore()
  const navigate = useNavigate()

  const [openDrawer, setOpenDrawer] = useState(false)
  const [openAccount, setOpenAccount] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notifications, setNotifications] = useState<any[]>([])

  const initialize = () => {
    authStore.getNotificationAction((newData) => {
      setNotifications(newData)
    })
  }

  const handleLogoutClick = () => {
    authStore.logoutAction()
  }

  const handleNotificationItemClick = (notification: any) => () => {
    authStore.readNotificationAction(notification.id, () => {
      if (notification.meta.type === NOTIFICATION_TYPES.APPLICATION) {
        let urlPrefix = "/applications/view/"

        if (authStore.user?.role === USER_ROLES.Administrator) {
          urlPrefix = "/admin/applications/view/"
        }

        navigate(urlPrefix + notification.meta.uuid)
        setOpenNotification(false)
        setOpenDrawer(false)
      }

      if (notification.meta.type === NOTIFICATION_TYPES.IntroducerLead) {
        let urlPrefix = "/leads/view/"

        if (authStore.user?.role === USER_ROLES.Administrator) {
          urlPrefix = "/admin/leads/view/"
        }

        navigate(urlPrefix + notification.meta.uuid)
        setOpenNotification(false)
        setOpenDrawer(false)
      }

      const updatedNotifications = notifications.map((n) => {
        if (n.id === notification.id) {
          return { ...n, is_read: true }
        }
        return n
      })
      setNotifications(updatedNotifications)
    })
  }

  useEffect(() => {
    initialize()
  }, [])

  return (
    <Sheet open={openDrawer} onOpenChange={setOpenDrawer}>
      <SheetTrigger asChild>
        <Button className="w-10 rounded-xl px-0">
          <IconDrawerOpen className="text-xl" />
        </Button>
      </SheetTrigger>

      <SheetContent
        side="left"
        className="flex w-full flex-col gap-0 bg-white p-0 md:min-w-[400px] md:max-w-[400px]"
        hideClose
      >
        <div className="flex h-[104px] w-full items-center justify-between px-6 py-8">
          <Logo />

          <SheetClose asChild>
            <Button className="w-10 rounded-xl px-0">
              <IconDrawerClose className="text-xl" />
            </Button>
          </SheetClose>
        </div>

        <div className="flex w-full flex-col px-6 py-8">
          <p className="text-main mb-2.5 text-sm font-semibold">Admin</p>
          <Link
            className="mb-2.5 flex items-center gap-1 py-1 text-sm"
            to="/admin/applications/list"
          >
            <IconMyApplications className="text-xl" />
            Applications
          </Link>
          <Link className="mb-2.5 flex items-center gap-1 py-1 text-sm" to="/admin/borrowers/list">
            <IconMyBorrowers className="text-xl" />
            Borrowers
          </Link>
          <Link className="mb-2.5 flex items-center gap-1 py-1 text-sm" to="/admin/users/list">
            <IconUserCircle className="text-xl" />
            Users
          </Link>
          <Link className="mb-2.5 flex items-center gap-1 py-1 text-sm" to="/admin/lenders/list">
            <IconLender className="text-xl" />
            Lenders
          </Link>
          <Link className="mb-10 flex items-center gap-1 py-1 text-sm" to="/admin/leads/list">
            <LuBoxes className="text-xl" />
            Leads
          </Link>

          <Popover open={openNotification} onOpenChange={setOpenNotification}>
            <PopoverTrigger>
              <div className="text-main relative mb-2.5 flex cursor-pointer items-center gap-2 py-1 text-sm font-semibold">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-[#F8F8F8]">
                  <IconNotification className="text-2xl" />
                </div>
                Notifications
                {notifications.filter((n) => !n.is_read).length > 0 && (
                  <div className="absolute right-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-[#ef4444] text-[10px] font-semibold text-white">
                    {notifications.filter((n) => !n.is_read).length}
                  </div>
                )}
              </div>
            </PopoverTrigger>

            <PopoverContent
              align="end"
              side="bottom"
              sideOffset={0}
              className="popover-content-width-same-as-its-trigger flex flex-col gap-4"
            >
              {notifications.length === 0 && (
                <div className="text-default text-center text-sm font-normal">
                  You have no new notifications.
                </div>
              )}

              {notifications.length > 0 &&
                notifications.map((n) => (
                  <div
                    key={n.id}
                    className="flex cursor-pointer items-center justify-between"
                    onClick={handleNotificationItemClick(n)}
                  >
                    <div className="flex items-center">
                      <Avatar className="mr-3 h-12 w-12">
                        <AvatarImage
                          className="h-12 w-12 rounded-full object-cover"
                          src={n.sender?.image}
                          alt={`${n.sender?.first_name} ${n.sender?.last_name}`}
                        />
                        <AvatarFallback className="uppercase">
                          {n.sender?.first_name?.[0]}
                          {n.sender?.last_name?.[0]}
                        </AvatarFallback>
                      </Avatar>

                      <div className="mr-1 flex flex-col gap-1">
                        <div
                          className="text-main text-sm font-normal"
                          dangerouslySetInnerHTML={{ __html: n.title }}
                        />

                        <div className="text-default text-[10px] font-normal">
                          {format(n.created_at, "MMM dd")} | {format(n.created_at, "h:mm aaa")}
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsx(
                        "min-h-2 min-w-2 rounded-full",
                        !n.is_read ? "bg-[#ef4444]" : ""
                      )}
                    ></div>
                  </div>
                ))}
            </PopoverContent>
          </Popover>

          <DropdownMenu open={openAccount} onOpenChange={setOpenAccount}>
            <DropdownMenuTrigger className="mb-4 outline-none">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Avatar>
                    <AvatarImage
                      className="h-10 w-10 rounded-full object-cover"
                      src={authStore.user?.image}
                      alt={`${authStore.user?.first_name} ${authStore.user?.last_name}`}
                    />
                    <AvatarFallback className="uppercase">
                      {authStore.user?.first_name[0]}
                      {authStore.user?.last_name[0]}
                    </AvatarFallback>
                  </Avatar>

                  <div className="flex flex-col">
                    <p className="text-main text-left text-sm font-semibold">
                      {authStore.user?.first_name} {authStore.user?.last_name}
                    </p>
                  </div>
                </div>

                <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#F8F8F8]">
                  {openAccount ? (
                    <LuChevronUp className="text-default text-xl" />
                  ) : (
                    <LuChevronDown className="text-default text-xl" />
                  )}
                </div>
              </div>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="flex w-[352px] flex-col gap-2 p-3" sideOffset={10}>
              <DropdownMenuItem asChild className="flex items-center gap-1">
                <Link className="text-default text-sm" to="/account-settings">
                  <IconAccountSettings className="text-xl" />
                  Account Settings
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={handleLogoutClick}
                className="flex items-center gap-1 text-[#EF4444]"
              >
                <IconLogOut className="text-xl" />
                Log Out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          <Link className="mb-10 w-full" to="/admin/dashboard">
            <Button className="h-auto w-full rounded-xl py-4 text-sm font-semibold text-white">
              <IconHome className="mr-2 text-base" /> Back to home
            </Button>
          </Link>
        </div>
      </SheetContent>
    </Sheet>
  )
}
