import React from "react"

import { Page, StyleSheet } from "@react-pdf/renderer"

import { FUNDING_SOLUTIONS } from "@repo/util/constant"

import ContentBorrowerSelection from "./components/ContentBorrowerSelection"
import ContentCommissionSplit from "./components/ContentCommissionSplit"
import ContentFundingSolution from "./components/ContentFundingSolution"
import ContentApplicantType from "./components/ContentApplicantType"
import ContentLoanType from "./components/ContentLoanType"
import ContentLoanDetails from "./components/ContentLoanDetails"
import ContentLoanDocumentType from "./components/ContentLoanDocumentType"
import ContentSecurityDetails from "./components/ContentSecurityDetails"
import ContentLenderSelection from "./components/ContentLenderSelection"
import ContentBorrowerDetails from "./components/ContentBorrowerDetails"
import ContentSupportingDocumentation from "./components/ContentSupportingDocumentation"

interface Props {
  data: Record<string, any>
}

export default function Content(props: Readonly<Props>) {
  const { data } = props

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Poppins",
      padding: 32,
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-between",
      gap: 32
    }
  })

  return (
    <>
      <Page wrap={false} size="A4" style={styles.page}>
        <ContentBorrowerSelection data={data} />

        <ContentCommissionSplit data={data} />

        <ContentApplicantType data={data} />

        <ContentFundingSolution data={data} />

        <ContentLoanType data={data} />

        {data?.funding_solution !== FUNDING_SOLUTIONS.ConstructionFinance.key && (
          <ContentLoanDocumentType data={data} />
        )}
      </Page>

      <Page wrap={false} size="A4" style={styles.page}>
        <ContentLoanDetails data={data} />

        <ContentSecurityDetails data={data} />

        <ContentLenderSelection data={data} />
      </Page>

      <Page wrap={false} size="A4" style={styles.page}>
        <ContentBorrowerDetails data={data} />
      </Page>

      <Page wrap={false} size="A4" style={styles.page}>
        <ContentSupportingDocumentation data={data} />
      </Page>
    </>
  )
}
