import React from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useAdminBorrowerStore from "@/stores/useAdminBorrowerStore"

import { Button } from "@repo/ui/components/ui/button"

import EntityDetails from "./sections/EntityDetails"
import DirectorDetails from "./sections/DirectorDetails"

const FormSchema = z
  .object({
    entity_name: z.string().min(1, { message: "Entity name is required" }),
    registered_business_name: z
      .string()
      .min(1, { message: "Registered business name is required" }),
    entity_type: z.string().min(1, { message: "Entity type is required" }),
    entity_industry: z.string().min(1, { message: "Entity industry is required" }),
    australian_business_number: z
      .string()
      .min(1, { message: "Australian business number is required" }),
    australian_company_number: z.string(),
    website: z.string().min(1, { message: "Website is required" }).url({
      message: "Please enter a valid website url"
    }),

    directors: z.array(
      z.object({
        role: z.string().min(1, { message: "Role is required" }),
        full_name: z.string().min(1, { message: "Full name is required" }),
        email: z.string().min(1, { message: "Email address is required" }),
        mobile_number: z.string().min(1, { message: "Mobile number is required" }),

        street_address: z.string().min(1, { message: "Street address is required" }),
        suburb: z.string().min(1, { message: "Suburb is required" }),
        state: z.string().min(1, { message: "State is required" }),
        postcode: z.string().min(1, { message: "Postcode is required" }),

        birth_date: z.coerce.date(),
        country: z.string().min(1, { message: "Country is required" }),
        city: z.string().min(1, { message: "City is required" })
      })
    )
  })
  .refine(
    (data) => {
      if (
        (data.entity_type === "Company" && !data.australian_company_number) ||
        (data.entity_type === "Trust" && !data.australian_company_number)
      ) {
        return false
      }
      return true
    },
    {
      message: "Australian company number is required",
      path: ["australian_company_number"]
    }
  )

type FormSchemaType = z.infer<typeof FormSchema>

export default function MainForm() {
  const store = useAdminBorrowerStore()
  const navigate = useNavigate()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 })
  const { ref: ref2, inView: inView2 } = useInView({ trackVisibility: true, delay: 100 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      entity_name: "",
      registered_business_name: "",
      entity_type: "",
      entity_industry: "",
      australian_business_number: "",
      australian_company_number: "",
      website: "",

      directors: [
        {
          role: "",
          full_name: "",
          email: "",
          mobile_number: "",

          street_address: "",
          suburb: "",
          state: "",
          postcode: "",

          birth_date: undefined,
          country: "",
          city: ""
        }
      ]
    }
  })

  const handleCancelClick = () => {
    navigate("/admin/borrowers/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    store.createAction(values, () => {
      navigate("/admin/borrowers/list")
    })
  }

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <div ref={ref1} id="entity-details">
              <EntityDetails />
            </div>

            <div ref={ref2} id="director-details">
              <DirectorDetails />
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "entity-details",
                label: "Entity Details",
                inView: inView1
              },
              {
                id: "director-details",
                label: "Director Details",
                inView: inView2
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton loading={store.loading} type="submit" className="px-6 py-4 md:!w-auto">
            Add borrower
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}
