import React, { useEffect, useState } from "react"

import clsx from "clsx"
import numeral from "numeral"
import { MdCheckCircle } from "react-icons/md"

interface Props {
  isSelected: boolean
  lender: Record<string, any>
  data: Record<string, any>
  onClick: () => void
}

export default function LenderItem(props: Readonly<Props>) {
  const { isSelected, lender, data, onClick } = props
  const [filteredFslt, setFilteredFslt] = useState<any>(null)

  useEffect(() => {
    const tempFilteredFslt = lender?.fs
      ?.filter((fs: any) => (fs.funding_solution = data.funding_solution))?.[0]
      ?.lt?.filter((lt: any) => lt.loan_type === data.loan_type)?.[0]

    setFilteredFslt(tempFilteredFslt)
  }, [lender, data])

  return (
    <div
      className={clsx(
        "relative flex w-full flex-col rounded-2xl border p-6",
        isSelected ? "border-primary" : ""
      )}
      onClick={onClick}
    >
      {isSelected && (
        <div className="absolute right-6 top-6">
          <MdCheckCircle className="text-primary" />
        </div>
      )}

      <h3 className="text-main mb-4 text-lg font-semibold">{lender.lender_number}</h3>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Interest Rate From</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.interest_rate ?? 0}%
            </span>
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Maximum LVR</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.loan_value_ratio ?? 0}%
            </span>
          </div>
        </div>

        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Min. Loan Size</span>
            <span className="text-main text-sm font-normal">
              {numeral(filteredFslt?.minimum_loan_size).format("$ 0[.]00 a")}
            </span>
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Max. Loan Size</span>
            <span className="text-main text-sm font-normal">
              {numeral(filteredFslt?.maximum_loan_size).format("$ 0[.]00 a")}
            </span>
          </div>
        </div>

        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Min. Loan Term</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.minimum_loan_term ?? 0} Months
            </span>
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Max. Loan Term</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.maximum_loan_term ?? 0} Months
            </span>
          </div>
        </div>

        <div className="flex flex-row gap-4 md:flex-col">
          <div className="flex flex-1 flex-col gap-2">
            <span className="text-default text-sm font-normal">Payment Options</span>
            <span className="text-main text-sm font-normal">
              {filteredFslt?.repayments?.map((r: string) => <div key={r}>{r}</div>)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
