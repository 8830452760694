import React, { useState } from "react"

import { ColumnDef } from "@tanstack/react-table"
import { GoDuplicate } from "react-icons/go"
import { useNavigate } from "react-router-dom"

import { IconArchive, IconEdit, IconMore, IconView } from "@/components/_icons"
import useAdminLenderStore from "@/stores/useAdminLenderStore"

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@repo/ui/components/ui/dropdown-menu"
import { Button } from "@repo/ui/components/ui/button"
import { LENDER_STATUS } from "@repo/util/constant"

import DuplicateConfirmDialog from "./DuplicateConfirmDialog"

interface ActionColumnProps {
  row: any
}

function ActionColumn({ row }: Readonly<ActionColumnProps>) {
  const navigate = useNavigate()
  const store = useAdminLenderStore()
  const [openDuplicate, setOpenDuplicate] = useState(false)

  const handleViewClick = (uuid: string) => () => {
    navigate(`/admin/lenders/view/${uuid}`)
  }

  const handleEditClick = (uuid: string) => () => {
    navigate(`/admin/lenders/edit/${uuid}`)
  }

  const handleDuplicateClick = () => {
    setOpenDuplicate(true)
  }

  const handleArchiveClick = (uuid: string) => () => {
    store.archiveAction(uuid)
  }

  return (
    <div className="text-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon">
            <IconMore className="text-2xl" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-32" align="end">
          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleViewClick(row.original.uuid)}
          >
            <IconView className="text-base" /> View
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleEditClick(row.original.uuid)}
          >
            <IconEdit className="text-base" />
            Edit
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleDuplicateClick}
          >
            <GoDuplicate className="text-base" />
            Duplicate
          </DropdownMenuItem>

          <DropdownMenuItem
            className="text-default flex gap-2 text-sm"
            onClick={handleArchiveClick(row.original.uuid)}
          >
            <IconArchive className="text-base" />
            {row.original.status === LENDER_STATUS.ACTIVE ? "Archive" : "Restore"}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DuplicateConfirmDialog
        targetData={row.original}
        open={openDuplicate}
        setOpen={setOpenDuplicate}
      />
    </div>
  )
}

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "lender_name",
    header: () => <>Lender Name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.lender_name}</div>
  },
  {
    accessorKey: "lender_number",
    header: () => <>Lender Number</>,
    cell: ({ row }) => <div className="text-sm">{row.original.lender_number}</div>
  },
  {
    accessorKey: "bdm_full_name",
    header: () => <>BDM Full Name</>,
    cell: ({ row }) => <div className="text-sm">{row.original.bdm_full_name}</div>
  },
  {
    accessorKey: "bdm_email",
    header: () => <>BDM Email Address</>,
    cell: ({ row }) => <div className="text-sm">{row.original.bdm_email}</div>
  },
  {
    accessorKey: "bdm_mobile_number",
    header: () => <>BDM Mobile Number</>,
    cell: ({ row }) => <div className="text-sm">{row.original.bdm_mobile_number}</div>
  },
  {
    accessorKey: "actions",
    header: () => <div className="text-center">Actions</div>,
    cell: ({ row }) => <ActionColumn row={row} />
  }
]

export default columns
