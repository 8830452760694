import React, { Dispatch, SetStateAction, useState } from "react"

import validator from "validator"

import { LoadingButton } from "@/components/_uiext"

import { Dialog, DialogContent } from "@repo/ui/components/ui/dialog"
import { Input } from "@repo/ui/components/ui/input"

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export default function InviteBorrowerDialog(props: Readonly<Props>) {
  const { open, setOpen } = props
  const [borrowerEmail, setBorrowerEmail] = useState("")

  const handleInviteClick = () => {
    // TODO - Implement invite action
    console.log("Invite email: ", borrowerEmail)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="flex h-full flex-col gap-0 bg-white p-10 md:h-auto">
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col">
            <h1 className="text-main mb-6 text-center text-lg font-semibold">Invite Borrower</h1>

            <div className="text-main mb-2 text-sm font-normal">Enter borrowers email address</div>

            <Input
              placeholder="borrower@email.com"
              className="mb-6"
              value={borrowerEmail}
              onChange={(e) => setBorrowerEmail(e.target.value)}
            />
          </div>

          <div className="flex justify-center">
            <LoadingButton
              type="button"
              disabled={!validator.isEmail(borrowerEmail)}
              onClick={handleInviteClick}
              className="w-full px-6 py-4 md:w-auto"
            >
              Invite
            </LoadingButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
