import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { IconAddBorrower, IconNext } from "@/components/_icons"
import { LoadingButton } from "@/components/_uiext"
import useAdminApplicationDraftStore from "@/stores/useAdminApplicationDraftStore"

import { Button } from "@repo/ui/components/ui/button"
import { Checkbox } from "@repo/ui/components/ui/checkbox"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"

import BorrowerAutoComplete from "../components/BorrowerAutoComplete"
import { LOADING_TIMEOUT } from "../../util"
import { useAdminAppContext } from "../../AdminAppContext"

const FormSchema = z.object({
  borrower_uuid: z.string({
    invalid_type_error: "Borrower is required",
    required_error: "Borrower is required"
  }),
  is_authorised: z.boolean({
    invalid_type_error: "This field is required",
    required_error: "This field is required"
  }),
  is_tfn_removed: z.boolean({
    invalid_type_error: "This field is required",
    required_error: "This field is required"
  })
})

type FormSchemaType = z.infer<typeof FormSchema>

export default function BorrowerSelection() {
  const { steps, activeStep, setActiveStep, setLoading, data, setData } = useAdminAppContext()
  const store = useAdminApplicationDraftStore()
  const navigate = useNavigate()

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      borrower_uuid: undefined,
      is_authorised: undefined,
      is_tfn_removed: undefined
    }
  })

  const handleAddBorrowerClick = () => {
    navigate("/admin/borrowers/add")
  }

  const handleBackClick = () => {
    navigate("/admin/dashboard")
  }

  const handleSaveClick = () => {
    setLoading(true)

    store.updateAction(
      data.uuid,
      {
        params: {
          ...form.getValues(),
          current_step: steps[activeStep]
        }
      },
      (newData) => {
        setData(newData)

        setTimeout(() => {
          setLoading(false)
          navigate("/admin/applications/list")
        }, LOADING_TIMEOUT)
      }
    )
  }

  const onSubmit = (values: FormSchemaType) => {
    store.updateAction(
      data.uuid,
      {
        params: {
          ...values,
          current_step: steps[activeStep + 1]
        }
      },
      (newData) => {
        setData(newData)
        setActiveStep((prev: number) => prev + 1)
      }
    )
  }

  useEffect(() => {
    form.reset({
      borrower_uuid: data?.borrower_uuid,
      is_authorised: data?.is_authorised,
      is_tfn_removed: data?.is_tfn_removed
    })
  }, [data])

  return (
    <Form {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col pr-0 xl:pr-[352px]">
            <h3 className="text-main mb-4 text-lg font-semibold">
              Select existing borrower or add a new borrower
            </h3>

            <div className="mb-6">
              <FormField
                control={form.control}
                name="borrower_uuid"
                render={({ field }) => (
                  <FormItem>
                    <BorrowerAutoComplete
                      applicationUuid={data?.uuid}
                      disabled={false}
                      value={field.value ?? ""}
                      onValueChange={field.onChange}
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6 flex items-center justify-between">
              <div className="h-[1px] w-full border-t border-[#CDCDCD]" />
              <div className="bg-white px-6 text-sm">or</div>
              <div className="h-[1px] w-full border-t border-[#CDCDCD]" />
            </div>

            <div className="mb-6 w-full">
              <Button
                type="button"
                onClick={handleAddBorrowerClick}
                className="h-auto w-full gap-4 px-6 py-4"
              >
                <IconAddBorrower className="text-base" />
                Add new borrower
              </Button>
            </div>

            <div className="mb-6 w-full">
              <FormField
                control={form.control}
                name="is_authorised"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        I am authorised to provide all personal information included in this
                        application to FundsConnect for the purpose of FundsConnect assessing this
                        application.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>

            <div className="mb-6 w-full">
              <FormField
                control={form.control}
                name="is_tfn_removed"
                render={({ field }) => (
                  <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={(checked) => {
                          field.onChange(checked)
                        }}
                        className="data-[state=checked]:border-primary h-5 w-5 rounded-[4px] border-[#CDCDCD]"
                      />
                    </FormControl>
                    <div className="flex flex-col">
                      <FormLabel className="text-main text-sm font-normal">
                        I confirm that I have removed TFNs (tax file numbers) from all supporting
                        documentation associated with this application.
                      </FormLabel>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-between pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto bg-transparent px-0 py-4 font-semibold hover:bg-transparent"
            onClick={handleSaveClick}
          >
            Save and exit
          </Button>

          <div className="flex justify-end gap-2">
            <Button
              type="button"
              className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
              onClick={handleBackClick}
            >
              Back
            </Button>

            <LoadingButton
              loading={store.locked}
              type="submit"
              className="flex items-center gap-4 px-6 py-4 md:!w-auto"
            >
              Next
              <IconNext className="text-sm" />
            </LoadingButton>
          </div>
        </div>
      </form>
    </Form>
  )
}
