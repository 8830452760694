import React from "react"

import { useFormContext } from "react-hook-form"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"

export default function BasicInformation() {
  const form = useFormContext()

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Basic information</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="lender_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Lender name</FormLabel>
                <Input disabled autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="lender_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Lender number</FormLabel>
                <Input disabled autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="bdm_full_name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">BDM full name</FormLabel>
                <Input disabled autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="bdm_email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">BDM email address</FormLabel>
                <Input disabled autoComplete="off" type="email" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="bdm_mobile_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">BDM mobile number</FormLabel>
                <Input disabled autoComplete="off" type="tel" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Website</FormLabel>
                <Input
                  disabled
                  type="url"
                  autoComplete="off"
                  onChange={field.onChange}
                  value={field.value ?? ""}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
