import React from "react"

import clsx from "clsx"
import { useFieldArray, useFormContext } from "react-hook-form"

import { FileUpload } from "@/components/_uiext"
import { Button } from "@repo/ui/components/ui/button"
import { FormControl, FormField, FormItem, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { CHECK_ITEM_TYPES, YN } from "@repo/util/constant"

interface Props {
  checkItemIndex: number
}

export default function SupportingDocumentationItems(props: Readonly<Props>) {
  const { checkItemIndex } = props
  const form = useFormContext()

  const { fields } = useFieldArray({
    control: form.control,
    name: `checkitems.${checkItemIndex}.items`
  })

  return (
    <div className="flex flex-col">
      {fields.map((item: any, index) => (
        <div key={item.id} className="mb-4 flex w-full flex-col">
          <div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
            <div className="text-main text-sm font-normal">{item.text}</div>

            <FormField
              control={form.control}
              name={`checkitems.${checkItemIndex}.items.${index}.choice`}
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <>
                      {(item.type === CHECK_ITEM_TYPES.Input ||
                        item.type === CHECK_ITEM_TYPES.Upload) && (
                        <div className="flex gap-2.5">
                          {[YN.Yes, YN.No].map((option) => (
                            <Button
                              key={option}
                              type="button"
                              size="sm"
                              className={clsx(
                                "h-auto border px-6 py-4 font-semibold capitalize",
                                field.value === option
                                  ? "border-primary"
                                  : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                              )}
                              onClick={() => field.onChange(option)}
                            >
                              {option}
                            </Button>
                          ))}
                        </div>
                      )}
                    </>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <div className="flex w-full md:justify-end">
            <FormField
              control={form.control}
              name={`checkitems.${checkItemIndex}.items.${index}.result`}
              render={({ field }) => (
                <FormItem className="flex w-full flex-col md:w-auto">
                  <FormControl>
                    <>
                      {item.type === CHECK_ITEM_TYPES.YesNo && (
                        <div className="flex gap-2.5">
                          {[YN.Yes, YN.No].map((option) => (
                            <Button
                              key={option}
                              type="button"
                              size="sm"
                              className={clsx(
                                "h-auto border px-6 py-4 font-semibold capitalize",
                                field.value === option
                                  ? "border-primary"
                                  : "text-default hover:bg-primary hover:border-primary border-[#868194] bg-transparent hover:text-white"
                              )}
                              onClick={() => field.onChange(option)}
                            >
                              {option}
                            </Button>
                          ))}
                        </div>
                      )}

                      {form.watch(`checkitems.${checkItemIndex}.items.${index}.choice`) ===
                        YN.Yes &&
                        item.type === CHECK_ITEM_TYPES.Input && (
                          <Input
                            className="w-full md:min-w-40 md:max-w-40"
                            autoComplete="off"
                            {...field}
                          />
                        )}

                      {form.watch(`checkitems.${checkItemIndex}.items.${index}.choice`) ===
                        YN.Yes &&
                        item.type === CHECK_ITEM_TYPES.Upload && (
                          <div className="w-full pt-8 md:min-w-40 md:max-w-40">
                            <FileUpload {...field} />
                          </div>
                        )}
                    </>
                  </FormControl>
                  {form.watch(`checkitems.${checkItemIndex}.items.${index}.choice`) === YN.Yes && (
                    <FormMessage />
                  )}
                </FormItem>
              )}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
