import React, { useEffect } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormProvider, useForm } from "react-hook-form"
import { useInView } from "react-intersection-observer"
import { useNavigate } from "react-router-dom"
import * as z from "zod"

import { LoadingButton, StickyShortcut } from "@/components/_uiext"
import useBusinessUserStore from "@/stores/useBusinessUserStore"
import useAuthStore from "@/stores/useAuthStore"

import { Button } from "@repo/ui/components/ui/button"

import BasicInformation from "./sections/BasicInformation"
import Membership from "./sections/Membership"
import ProfessionalIndemnityInsurance from "./sections/ProfessionalIndemnityInsurance"
import AustralianCreditRepresentative from "./sections/AustralianCreditRepresentative"

const FormSchema = z.object({
  image: z.any().refine((file) => file !== null, "Image is required"),
  first_name: z.string().min(1, { message: "First name is required" }),
  last_name: z.string().min(1, { message: "Last name is required" }),
  email: z.string().email({
    message: "Please enter a valid email address"
  }),
  mobile_number: z.string().min(1, {
    message: "Please enter a valid mobile number"
  }),
  membership_association: z.string().min(1, { message: "Association is required" }),
  membership_certificate: z
    .string()
    .min(1, { message: "Membership Certificate Number is required" }),
  membership_expiry: z.coerce.date(),
  acr_certificate: z.string().min(1, { message: "This field is required" }),
  acr_expiry: z.coerce.date(),
  insurance_certificate: z.string().min(1, { message: "Insurance Policy Number is required" }),
  insurance_expiry: z.coerce.date()
})

type FormSchemaType = z.infer<typeof FormSchema>

interface Props {
  data: any
}

export default function MainForm({ data }: Readonly<Props>) {
  const authStore = useAuthStore()

  const store = useBusinessUserStore()
  const navigate = useNavigate()

  const { ref: ref1, inView: inView1 } = useInView({ threshold: 1 })
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 1 })
  const { ref: ref3, inView: inView3 } = useInView({ threshold: 1 })
  const { ref: ref4, inView: inView4 } = useInView({ threshold: 1 })

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      image: null,
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      membership_association: "",
      membership_certificate: "",
      membership_expiry: undefined,
      acr_certificate: "",
      acr_expiry: undefined,
      insurance_certificate: "",
      insurance_expiry: undefined
    }
  })

  const handleCancelClick = () => {
    navigate("/business/users/list")
  }

  const onSubmit = (values: FormSchemaType) => {
    if (values.email.split("@")[1] !== authStore.user?.email.split("@")[1]) {
      form.setError("email", {
        type: "custom",
        message: `Email domain should be ${authStore.user?.email.split("@")[1]}.`
      })
      return
    }

    store.updateAction(data.user_uuid, values, () => {
      navigate("/business/users/list")
    })
  }

  useEffect(() => {
    form.reset({
      image: data?.user?.image,
      first_name: data?.user?.first_name,
      last_name: data?.user?.last_name,
      email: data?.user?.email,
      mobile_number: data?.user?.mobile_number,
      membership_association: data?.membership_association,
      membership_certificate: data?.membership_certificate,
      membership_expiry: data?.membership_expiry,
      acr_certificate: data?.acr_certificate,
      acr_expiry: data?.acr_expiry,
      insurance_certificate: data?.insurance_certificate,
      insurance_expiry: data?.insurance_expiry
    })
  }, [data])

  return (
    <FormProvider {...form}>
      <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex w-full rounded-xl border border-[#ECECEC] bg-white p-8">
          <div className="flex w-full flex-1 flex-col gap-5 xl:pr-8">
            <div ref={ref1} id="basic-information">
              <BasicInformation />
            </div>

            <div ref={ref2} id="membership">
              <Membership />
            </div>

            <div ref={ref3} id="australian-credit-representative">
              <AustralianCreditRepresentative />
            </div>

            <div ref={ref4} id="professional-indemnity-insurance">
              <ProfessionalIndemnityInsurance />
            </div>
          </div>

          <StickyShortcut
            items={[
              {
                id: "basic-information",
                label: "Basic information",
                inView: inView1
              },
              {
                id: "membership",
                label: "Membership",
                inView: inView2
              },
              {
                id: "australian-credit-representative",
                label: "Australian Credit Representative",
                inView: inView3
              },
              {
                id: "professional-indemnity-insurance",
                label: "Professional indemnity insurance",
                inView: inView4
              }
            ]}
          />
        </div>

        <div className="flex w-full justify-end gap-2 pb-2 pt-6">
          <Button
            type="button"
            className="text-default h-auto border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>

          <LoadingButton loading={store.loading} type="submit" className="px-6 py-4 md:!w-auto">
            Update account
          </LoadingButton>
        </div>
      </form>
    </FormProvider>
  )
}
