import { toast } from "sonner"
import { create } from "zustand"

import {
  apiArchiveAdminBorrower,
  apiCreateAdminBorrower,
  apiGetAdminBorrowerByUuid,
  apiGetAdminBorrowersList,
  apiUpdateAdminBorrower
} from "@/api/adminBorrowersApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAdminBorrowerState {
  loading: boolean
  reloadRequired: boolean

  getListAction: (params: any, onSuccessCallback?: (data: any, totalCount: number) => void) => void
  getOneByUuidAction: (uuid: string, onSuccessCallback?: (data: any) => void) => void
  createAction: (data: any, onSuccessCallback?: () => void) => void
  updateAction: (uuid: string, data: any, onSuccessCallback?: () => void) => void
  archiveAction: (uuid: string) => void
}

const useAdminBorrowerStore = create<IAdminBorrowerState>()((set, get) => ({
  loading: false,
  reloadRequired: false,

  getListAction: async (params, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminBorrowersList(params)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data.data, data.totalCount)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getOneByUuidAction: async (uuid, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiGetAdminBorrowerByUuid(uuid)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  createAction: async (data: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiCreateAdminBorrower(data)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  updateAction: async (uuid: string, data: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiUpdateAdminBorrower(uuid, data)

      if (response?.data) {
        const { message } = response.data

        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  archiveAction: async (uuid: string) => {
    try {
      const response = await apiArchiveAdminBorrower(uuid)

      if (response?.data) {
        const { message } = response.data
        set({ reloadRequired: !get().reloadRequired })
        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useAdminBorrowerStore
