import React from "react"

import { Text, View } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export default function ContentBorrowerSelection(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 18
        }}
      >
        Borrower Selection
      </Text>

      <View
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: "1px solid #CDCDCD",
          borderRadius: 8
        }}
      >
        <Text style={{ color: "#0E052B", fontWeight: 600, fontSize: 16 }}>
          {data?.borrower?.entity_name}
        </Text>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Full name</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.borrower?.directors?.[0]?.full_name}
            </Text>
          </View>

          <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Mobile number</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.borrower?.directors?.[0]?.mobile_number}
            </Text>
          </View>

          <View style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Email address</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.borrower?.directors?.[0]?.email}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
