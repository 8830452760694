import qs from "qs"

import api from "./api"

// Get borrowers
export const apiGetBorrowersAtApplicationDraft = (applicationUuid: string, params: any) =>
  api().get(`/application/drafts/${applicationUuid}/get-borrowers?${qs.stringify(params)}`)

// Find Lender
export const apiFindLendersAtApplicationDraft = (uuid: string, params: any) =>
  api().get(`/application/drafts/${uuid}/find-lenders?${qs.stringify(params)}`)

// Create
export const apiCreateAtApplicationDraft = (params: any) =>
  api().postForm("/application/drafts", params)

// Update
export const apiUpdateAtApplicationDraft = (uuid: string, params: any) =>
  api().putForm(`/application/drafts/${uuid}`, params)

// Submit
export const apiSubmitAtApplicationDraft = (uuid: string) =>
  api().patch(`/application/drafts/${uuid}`)
