import React from "react"

import { Page, Text, View, StyleSheet } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export default function Cover(props: Readonly<Props>) {
  const { data } = props

  const styles = StyleSheet.create({
    page: {
      padding: 32,
      display: "flex",
      flexDirection: "column",
      fontFamily: "Poppins"
    }
  })

  return (
    <Page size="A4" style={styles.page}>
      <View
        style={{
          height: "25%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 16
        }}
      >
        <Text
          style={{
            fontSize: 32,
            fontWeight: 700,
            textAlign: "center"
          }}
        >
          Application Summary
        </Text>

        <Text
          style={{
            fontSize: 20,
            fontWeight: 500,
            textAlign: "center"
          }}
        >
          Application ID: {data?.id?.toString().padStart(10, "0")}
        </Text>
      </View>
    </Page>
  )
}
