import React, { useState } from "react"

import clsx from "clsx"
import { getNames } from "country-list"
import { format } from "date-fns"
import { useFieldArray, useFormContext } from "react-hook-form"

import { IconCalendar } from "@/components/_icons"

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@repo/ui/components/ui/accordion"
import { Button } from "@repo/ui/components/ui/button"
import { Calendar } from "@repo/ui/components/ui/calendar"
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import { Popover, PopoverContent, PopoverTrigger } from "@repo/ui/components/ui/popover"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { BORROWER_DIRECTOR_ROLES, MAXIMUM_DIRECTOR_COUNT } from "@repo/util/constant"
import { AUSTRALIA_STATES } from "@repo/util/constant"
import { toast } from "sonner"
import { CLIENT_MSG } from "@repo/i18n"

export default function DirectorDetails() {
  const form = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "directors"
  })

  const [openAccordion, setOpenAccordion] = useState<string[]>([`director-0`])
  const [openCalendars, setOpenCalendars] = useState<boolean[]>(
    [...Array(MAXIMUM_DIRECTOR_COUNT).keys()].map((x) => false)
  )

  const handleAddClick = () => {
    if (fields.length < MAXIMUM_DIRECTOR_COUNT) {
      append({
        role: "",
        full_name: "",
        email: "",
        mobile_number: "",

        street_address: "",
        suburb: "",
        state: "",
        postcode: "",

        birth_date: undefined,
        country: "",
        city: ""
      })

      setOpenAccordion((prev: string[]) => [...prev, `director-${fields.length}`])
    } else {
      toast.error(CLIENT_MSG.BORROWER_DIRECTORS_LIMITED)
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="mb-4 flex items-center justify-between">
        <h3 className="text-main py-4 text-lg font-semibold">Director Details</h3>

        <Button
          type="button"
          className="text-default h-auto rounded-xl border border-[#868194] bg-transparent px-6 py-4 font-semibold hover:bg-transparent"
          onClick={handleAddClick}
        >
          Add
        </Button>
      </div>

      <Accordion
        type="multiple"
        className="w-full"
        value={openAccordion}
        onValueChange={setOpenAccordion}
      >
        {fields.map((item, index) => (
          <AccordionItem
            className="mb-4 rounded-2xl border p-6"
            key={item.id}
            value={`director-${index}`}
          >
            <AccordionTrigger className="h-24 hover:no-underline md:h-12" tabIndex={-1}>
              <div className="flex flex-wrap items-center gap-4">
                <div
                  className={clsx(
                    "flex min-h-12 min-w-12 items-center justify-center rounded-full border",
                    openAccordion.includes(`director-${index}`)
                      ? "border-primary text-primary"
                      : "border-[#CDCDCD] text-[#CDCDCD]"
                  )}
                >
                  {index + 1}
                </div>
                <div className="text-main text-lg font-semibold">Director Details</div>

                <span
                  className="bg-destructive flex items-center gap-2 rounded-xl px-6 py-4 text-sm text-white"
                  onClick={() => remove(index)}
                >
                  Remove
                </span>
              </div>
            </AccordionTrigger>
            <AccordionContent className="flex flex-col px-1 pt-6">
              <h4 className="text-main mb-4 text-lg font-semibold">Key Information</h4>

              <div className="mb-4 w-full">
                <FormField
                  control={form.control}
                  name={`directors.${index}.role`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-main text-sm font-normal">Role</FormLabel>
                      <FormControl>
                        <div className="flex flex-wrap items-center gap-2.5">
                          {Object.entries(BORROWER_DIRECTOR_ROLES).map(([k, v]) => (
                            <Button
                              key={k}
                              type="button"
                              className={clsx(
                                "h-auto border px-6 py-4 font-semibold capitalize",
                                field.value === v
                                  ? "border-primary"
                                  : "text-default border-[#868194] bg-transparent hover:bg-transparent"
                              )}
                              onClick={() => field.onChange(v)}
                            >
                              {v}
                            </Button>
                          ))}
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.full_name`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Full name</FormLabel>
                        <Input autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.email`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Email address
                        </FormLabel>
                        <Input type="email" autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-6 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.mobile_number`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Mobile number
                        </FormLabel>
                        <Input autoComplete="off" type="tel" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="hidden flex-1 md:block" />
              </div>

              <h4 className="text-main mb-4 text-lg font-semibold">Residential Address</h4>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.street_address`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">
                          Street address
                        </FormLabel>
                        <Input autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.suburb`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Suburb</FormLabel>
                        <Input autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="mb-6 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.state`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">State</FormLabel>
                        <Select onValueChange={field.onChange} value={field.value}>
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {AUSTRALIA_STATES.map((as) => (
                              <SelectItem key={as} value={as}>
                                {as}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.postcode`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">Postcode</FormLabel>
                        <Input autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <h4 className="text-main mb-4 text-lg font-semibold">Birth Details</h4>

              <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.birth_date`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-main mb-1 text-sm font-normal">
                          Birth date
                        </FormLabel>
                        <Popover
                          open={openCalendars[index]}
                          onOpenChange={(open) =>
                            setOpenCalendars((prev) => {
                              let updated = [...prev]
                              updated[index] = open
                              return updated
                            })
                          }
                        >
                          <PopoverTrigger asChild>
                            <FormControl>
                              <div className="relative">
                                <Input
                                  className="cursor-pointer"
                                  readOnly
                                  value={field.value ? format(field.value, "dd/MM/yyyy") : ""}
                                />
                                <div className="absolute right-[17.5px] top-[50%] translate-y-[-50%]">
                                  <IconCalendar className="text-default text-lg" />
                                </div>
                              </div>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              initialFocus
                              mode="single"
                              selected={field.value}
                              onSelect={(date) => {
                                field.onChange(date)
                                setOpenCalendars((prev) => {
                                  let updated = [...prev]
                                  updated[index] = false
                                  return updated
                                })
                              }}
                              disabled={(date) => date < new Date("1900-01-01")}
                            />
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <div className="flex w-full flex-col gap-4 md:flex-row md:gap-8">
                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.country`}
                    render={({ field }) => (
                      <FormItem className="flex flex-col">
                        <FormLabel className="text-main text-sm font-normal">Country</FormLabel>
                        <Select onValueChange={field.onChange} value={field.value}>
                          <SelectTrigger
                            className={clsx(
                              "h-12 w-full text-sm",
                              field.value ? "text-main" : "text-default"
                            )}
                          >
                            <SelectValue placeholder="Please select" />
                          </SelectTrigger>
                          <SelectContent>
                            {getNames()
                              .sort((a, b) => a.localeCompare(b))
                              .map((as) => (
                                <SelectItem key={as} value={as}>
                                  {as}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div className="flex-1">
                  <FormField
                    control={form.control}
                    name={`directors.${index}.city`}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="text-main text-sm font-normal">City</FormLabel>
                        <Input autoComplete="off" {...field} />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
