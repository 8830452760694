import React from "react"

import { Link } from "react-router-dom"

import useAuthStore from "@/stores/useAuthStore"

export default function Logo() {
  const authStore = useAuthStore()

  return (
    <Link to={authStore.user?.is_admin ? "/admin/dashboard" : "/dashboard"}>
      <img className="h-6 min-h-6 w-[218px] min-w-[218px]" src="/logo.svg" alt="Logo" />
    </Link>
  )
}
