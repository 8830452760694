import React from "react"

import { ColumnDef } from "@tanstack/react-table"
import numeral from "numeral"

import { Avatar, AvatarFallback, AvatarImage } from "@repo/ui/components/ui/avatar"

const columns: ColumnDef<any>[] = [
  {
    accessorKey: "full_name",
    header: () => <>Full Name</>,
    cell: ({ row }) => (
      <div className="flex items-center gap-2.5">
        <Avatar>
          <AvatarImage
            className="h-10 w-10 rounded-full object-cover"
            src={row.original.creator.image}
            alt={`${row.original.creator.first_name} ${row.original.creator.last_name}`}
          />
          <AvatarFallback className="uppercase">
            {row.original.creator.first_name[0]}
            {row.original.creator.last_name[0]}
          </AvatarFallback>
        </Avatar>

        <div className="text-sm">
          {row.original.creator.first_name} {row.original.creator.last_name}
        </div>
      </div>
    )
  },
  {
    accessorKey: "entity_name",
    header: () => <>Entity Name</>,
    cell: ({ row }) => (
      <div className="text-sm">{row.original.creator.bs?.registered_business_name}</div>
    )
  },
  {
    accessorKey: "total_applications",
    header: () => <>Total Applications</>,
    cell: ({ row }) => <div className="text-sm">{row.original.total_applications}</div>
  },
  {
    accessorKey: "total_settled_amount",
    header: () => <>Total Settled Amount</>,
    cell: ({ row }) => (
      <div className="text-nowrap text-sm">
        {numeral(row.original.total_settled_amount).format("$ 0,0")}
      </div>
    )
  },
  {
    accessorKey: "total_commissions_earned",
    header: () => <>Total Commission Earned</>,
    cell: ({ row }) => (
      <div className="text-sm">
        {numeral(row.original.total_commissions_earned).format("$ 0,0")}
      </div>
    )
  }
]

export default columns
