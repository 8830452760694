import React, { useEffect } from "react"

import clsx from "clsx"
import { useFormContext } from "react-hook-form"

import ApiAusAbr from "@/api/ausAbrApi"
import { EntityAutoComplete } from "@/components/_uiext"

import { FormField, FormItem, FormLabel, FormMessage } from "@repo/ui/components/ui/form"
import { Input } from "@repo/ui/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@repo/ui/components/ui/select"
import { ENTITY_INDUSTRIES, ENTITY_TYPES, abnEntity2fcEntity } from "@repo/util/constant"

export default function EntityDetails() {
  const form = useFormContext()

  useEffect(() => {
    if (
      form.watch("entity_type") === ENTITY_TYPES.CompanyPrivate.key ||
      form.watch("entity_type") === ENTITY_TYPES.CompanyPublic.key
    ) {
      const abn: string = form.watch("australian_business_number")
      form.setValue("australian_company_number", abn.substring(3))
    } else {
      form.setValue("australian_company_number", "")
    }
  }, [form.watch("australian_business_number"), form.watch("entity_type")])

  return (
    <div className="flex w-full flex-col">
      <h3 className="text-main mb-4 py-4 text-lg font-semibold">Entity Details</h3>

      <div className="mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8">
        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_type"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Entity type</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <SelectTrigger
                    autoFocus
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(ENTITY_TYPES).map(([k, v]) => (
                      <SelectItem key={k} value={v.key}>
                        {v.key}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="hidden flex-1 md:block" />
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="temp_abn"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Registered business name
                </FormLabel>

                <EntityAutoComplete
                  disabled={false}
                  value={field.value}
                  nameValue={form.getValues("registered_business_name")}
                  onValueChange={async (v) => {
                    field.onChange(v.Abn)
                    form.setValue("registered_business_name", v.Name)
                    form.setValue("australian_business_number", v.Abn)

                    const detail = await ApiAusAbr.getByAbn(v.Abn)
                    const newEntityType = abnEntity2fcEntity(detail?.EntityTypeName)
                    if (newEntityType !== null && form.watch("entity_type") !== newEntityType) {
                      form.setValue("entity_type", newEntityType)
                    }
                  }}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="australian_business_number"
            render={({ field }) => (
              <FormItem
                className={clsx(
                  form.watch("entity_type") === ENTITY_TYPES.CompanyPrivate.key ||
                    form.watch("entity_type") === ENTITY_TYPES.CompanyPublic.key
                    ? "hidden"
                    : ""
                )}
              >
                <FormLabel className="text-main text-sm font-normal">
                  Australian business number (ABN)
                </FormLabel>
                <Input disabled={!form.watch("entity_type")} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          (!form.watch("entity_type") ||
            (form.watch("entity_type") !== ENTITY_TYPES.CompanyPrivate.key &&
              form.watch("entity_type") !== ENTITY_TYPES.CompanyPublic.key)) &&
            "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="australian_business_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Australian business number (ABN)
                </FormLabel>
                <Input disabled={!form.watch("entity_type")} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <div
            className={clsx(
              "",
              form.watch("entity_type") === ENTITY_TYPES.CompanyPrivate.key ||
                form.watch("entity_type") === ENTITY_TYPES.CompanyPublic.key
                ? "block"
                : "hidden"
            )}
          >
            <FormField
              control={form.control}
              name="australian_company_number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-main text-sm font-normal">
                    Australian company number (ACN)
                  </FormLabel>
                  <Input disabled={!form.watch("entity_type")} autoComplete="off" {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Entity email address
                </FormLabel>
                <Input
                  disabled={!form.watch("entity_type")}
                  type="email"
                  autoComplete="off"
                  {...field}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_contact_number"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">
                  Entity contact number
                </FormLabel>
                <Input disabled={!form.watch("entity_type")} autoComplete="off" {...field} />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <div
        className={clsx(
          "mb-4 flex w-full flex-col gap-4 md:flex-row md:gap-8",
          !form.watch("entity_type") && "hidden"
        )}
      >
        <div className="flex-1">
          <FormField
            control={form.control}
            name="website"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Website</FormLabel>
                <Input
                  disabled={!form.watch("entity_type")}
                  type="url"
                  autoComplete="off"
                  {...field}
                />
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="flex-1">
          <FormField
            control={form.control}
            name="entity_industry"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-main text-sm font-normal">Entity industry</FormLabel>
                <Select
                  disabled={!form.watch("entity_type")}
                  onValueChange={field.onChange}
                  value={field.value}
                >
                  <SelectTrigger
                    className={clsx(
                      "h-12 w-full text-sm",
                      field.value ? "text-main" : "text-default"
                    )}
                  >
                    <SelectValue placeholder="Please select" />
                  </SelectTrigger>
                  <SelectContent>
                    {ENTITY_INDUSTRIES.map((ei) => (
                      <SelectItem key={ei} value={ei}>
                        {ei}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  )
}
