import React from "react"

import { Text, View } from "@react-pdf/renderer"

interface Props {
  data: Record<string, any>
}

export default function ContentSecurityDetails(props: Readonly<Props>) {
  const { data } = props

  return (
    <View style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      <Text
        style={{
          color: "#0E052B",
          fontWeight: 600,
          fontSize: 18
        }}
      >
        Security Details
      </Text>

      <View
        style={{
          padding: 24,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: "1px solid #CDCDCD",
          borderRadius: 8
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
              Non specialised security
            </Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.non_specialised_security}
            </Text>
          </View>

          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>
              Specialised security
            </Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.specialised_security}
            </Text>
          </View>
        </View>

        <Text
          style={{
            color: "#0E052B",
            fontWeight: 600,
            fontSize: 16
          }}
        >
          Address
        </Text>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Street address</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.street_address}
            </Text>
          </View>

          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Suburb</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>{data?.suburb}</Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>State</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>{data?.state}</Text>
          </View>

          <View
            style={{
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: "0%",
              display: "flex",
              flexDirection: "column",
              gap: 8
            }}
          >
            <Text style={{ color: "#868194", fontWeight: 400, fontSize: 14 }}>Postcode</Text>
            <Text style={{ color: "#0E052B", fontWeight: 400, fontSize: 14 }}>
              {data?.postcode}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}
