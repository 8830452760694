import { toast } from "sonner"
import { create } from "zustand"

import {
  apiLogin,
  apiLogout,
  apiRefreshToken,
  apiUpdateProfile,
  apiVerifyTotp,
  apiGetNotification,
  apiReadNotification
} from "@/api/authApi"

import { CLIENT_MSG } from "@repo/i18n"

interface IAuthState {
  loading: boolean
  initialized: boolean

  mfaVerified: boolean
  user: Record<string, any> | null

  loginAction: (data: any, onSuccessCallback?: () => void) => void
  verifyTotpAction: (
    token: string,
    onSuccessCallback?: () => void,
    onFailureCallback?: () => void
  ) => void
  logoutAction: (onSuccessCallback?: () => void) => void
  refreshTokenAction: (onSuccessCallback?: () => void) => void

  updateProfileAction: (data: any) => void
  getNotificationAction: (onSuccessCallback?: (newData: any) => void) => void
  readNotificationAction: (notificationId: number, onSuccessCallback?: () => void) => void
}

const useAuthStore = create<IAuthState>()((set, get) => ({
  initialized: false,

  loading: false,
  mfaVerified: false,
  user: null,

  loginAction: async (data: any, onSuccessCallback) => {
    try {
      set({ loading: true })

      const response = await apiLogin(data)

      if (response?.data) {
        const { data } = response.data

        set({ user: data })
        set({ mfaVerified: false })

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      set({ user: null })
      set({ mfaVerified: false })

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  verifyTotpAction: async (token, onSuccessCallback, onFailureCallback) => {
    try {
      set({ loading: true })

      const response = await apiVerifyTotp({
        token,
        user_uuid: get().user?.uuid
      })

      if (response?.data) {
        const { message } = response.data

        set({ mfaVerified: true })
        toast.success(message)

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      set({ mfaVerified: false })

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
      if (typeof onFailureCallback === "function") {
        onFailureCallback()
      }
    } finally {
      set({ loading: false })
    }
  },

  logoutAction: async (onSuccessCallback) => {
    try {
      set({ loading: true })
      await apiLogout()

      set({ user: null })
      set({ mfaVerified: false })

      if (typeof onSuccessCallback === "function") {
        onSuccessCallback()
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  refreshTokenAction: async (onSuccessCallback) => {
    try {
      set({ loading: true })
      set({ initialized: false })

      set({ user: null })
      set({ mfaVerified: false })

      const response = await apiRefreshToken()

      if (response?.data) {
        const { data } = response.data

        set({ user: data })
        set({ mfaVerified: true })

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)
    } finally {
      set({ loading: false })
      set({ initialized: true })
    }
  },

  updateProfileAction: async (data) => {
    try {
      set({ loading: true })

      const response = await apiUpdateProfile(data)

      if (response?.data) {
        const { data, message } = response.data

        set({ user: data })

        toast.success(message)
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    } finally {
      set({ loading: false })
    }
  },

  getNotificationAction: async (onSuccessCallback) => {
    try {
      const response = await apiGetNotification()

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback(data)
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  },

  readNotificationAction: async (notificationId, onSuccessCallback) => {
    try {
      const response = await apiReadNotification(notificationId)

      if (response?.data) {
        const { data } = response.data

        if (typeof onSuccessCallback === "function") {
          onSuccessCallback()
        }
      }
    } catch (err: any) {
      console.error(err)

      if (err?.response?.data?.message) {
        toast.error(err.response.data.message)
      } else {
        toast.error(CLIENT_MSG.SOMETHING_WENT_WRONG)
      }
    }
  }
}))

export default useAuthStore
