import React, { useState, useRef, useCallback, type KeyboardEvent, useEffect } from "react"

import clsx from "clsx"
import { Command as CommandPrimitive } from "cmdk"
import { LuCheck } from "react-icons/lu"
import { Oval } from "react-loader-spinner"
import { useDebounce } from "use-debounce"

import useApplicationDraftStore from "@/stores/useApplicationDraftStore"

import {
  CommandGroup,
  CommandItem,
  CommandList,
  CommandInput,
  CommandEmpty
} from "@repo/ui/components/ui/command"

interface Props {
  applicationUuid: string
  disabled?: boolean
  placeholder?: string
  value?: string
  onValueChange?: (value: string) => void
}

export default function BorrowerAutoComplete(props: Readonly<Props>) {
  const {
    applicationUuid,
    disabled,
    placeholder = "No borrower found.",
    value = null,
    onValueChange
  } = props

  const store = useApplicationDraftStore()
  const inputRef = useRef<HTMLInputElement>(null)
  const isInitialized = useRef(false)

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const [items, setItems] = useState<any[]>([])
  const [selectedItem, setSelectedItem] = useState<Record<string, any> | null>(null)

  const [shouldSearch, setShouldSearch] = useState(false)
  const [inputValue, setInputValue] = useState<string>("")
  const [debouncedInputValue] = useDebounce(inputValue, 300)

  const initialize = () => {
    if (applicationUuid) {
      setLoading(true)
      store.getBorrowersAction(
        applicationUuid,
        {
          filter: { entity_name: "" },
          sort: [{ column: "entity_name", direction: "asc" }]
        },
        (newData) => {
          isInitialized.current = true

          const newItems = newData.data ?? []

          if (value) {
            const newSelectedItem = newItems?.filter((d: any) => d.uuid === value)?.[0]
            setSelectedItem(newSelectedItem)
            setInputValue(newSelectedItem?.entity_name)
          }

          setItems(newItems)

          setTimeout(() => {
            setLoading(false)
          }, 1000)
        }
      )
    }
  }

  const searchBorrower = async () => {
    if (applicationUuid) {
      setLoading(true)
      store.getBorrowersAction(
        applicationUuid,
        {
          filter: { entity_name: debouncedInputValue ?? "" },
          sort: [{ column: "entity_name", direction: "asc" }]
        },
        (newData) => {
          const newItems = newData.data ?? []
          setItems(newItems)

          setTimeout(() => {
            setLoading(false)
          }, 1000)
        }
      )
    }
  }

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (!input) {
        return
      }

      // Keep the options displayed when the user is typing
      if (!open) {
        setOpen(true)
      }

      // This is not a default behavior of the <input /> field
      if (event.key === "Enter" && input.value !== "") {
        const optionToSelect = items.find((option) => option.entity_name === input.value)
        if (optionToSelect) {
          setSelectedItem(optionToSelect)
          onValueChange?.(optionToSelect)
        }
      }

      if (event.key === "Escape") {
        input.blur()
      }
    },
    [open, items, onValueChange]
  )

  const handleBlur = useCallback(() => {
    setOpen(false)
    setInputValue(selectedItem?.entity_name)
  }, [selectedItem])

  const handleSelectOption = useCallback(
    (opt: Record<string, any>) => {
      setShouldSearch(false)
      setInputValue(opt.entity_name)
      setSelectedItem(opt)
      onValueChange?.(opt.uuid)

      // This is a hack to prevent the input from being focused after the user selects an option
      setTimeout(() => {
        inputRef?.current?.blur()
      }, 0)
    },
    [onValueChange]
  )

  useEffect(() => {
    if (!isInitialized.current) {
      initialize()
    }
  }, [value, applicationUuid])

  useEffect(() => {
    if (debouncedInputValue && shouldSearch) {
      searchBorrower()
    }
  }, [debouncedInputValue])

  return (
    <CommandPrimitive onKeyDown={handleKeyDown} shouldFilter={false}>
      <CommandInput
        ref={inputRef}
        value={inputValue}
        onValueChange={(v) => {
          setInputValue(v)
          setShouldSearch(true)
        }}
        onBlur={handleBlur}
        onFocus={() => setOpen(true)}
        placeholder="Select existing borrower"
        disabled={disabled}
        className="text-main placeholder:text-default h-12 text-sm"
      />

      <div className="relative mt-1">
        <div
          className={clsx(
            "animate-in fade-in-0 zoom-in-95 absolute top-0 z-10 w-full rounded-xl bg-white outline-none",
            open ? "block" : "hidden"
          )}
        >
          <CommandList className="rounded-lg ring-1 ring-slate-200">
            {loading && (
              <div className="flex justify-center p-4">
                <Oval
                  visible={true}
                  height="40"
                  width="40"
                  color="#0E052B"
                  secondaryColor="#868194"
                />
              </div>
            )}

            {!loading && (
              <>
                <CommandEmpty>{placeholder}</CommandEmpty>
                <CommandGroup>
                  {items.map((opt) => (
                    <CommandItem
                      key={opt.uuid}
                      value={opt.uuid}
                      onMouseDown={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                      }}
                      onSelect={() => handleSelectOption(opt)}
                      className="flex items-center justify-between gap-2.5"
                    >
                      <span
                        className={clsx(
                          "",
                          selectedItem?.uuid === opt.uuid && "text-base font-semibold"
                        )}
                      >
                        {opt.entity_name}
                      </span>

                      {selectedItem?.uuid === opt.uuid && (
                        <LuCheck className="text-main text-base" />
                      )}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </>
            )}
          </CommandList>
        </div>
      </div>
    </CommandPrimitive>
  )
}
